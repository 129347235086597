// another function to go from string to ByteArray, but we first encode the

import { IRegisteredCredentials } from "../type";

// string as base64 - note the use of the atob() function
export const strToBin = (str: string) => {
  return Uint8Array.from(atob(str), (c) => c.charCodeAt(0));
};

// function to encode raw binary to string, which is subsequently
// encoded to base64 - note the use of the btoa() function

export const binToStr = (bin: any) => {
  return btoa(
    new Uint8Array(bin).reduce((s, byte) => s + String.fromCharCode(byte), "")
  );
};

export const getRegistrations = () => {
  const registrations = JSON.parse(localStorage.exchageAppRegistration || "[]");
  return registrations;
};

export const setRegistrations = (registrations: IRegisteredCredentials) => {
  localStorage.exchageAppRegistration = JSON.stringify(
    registrations,
    null,
    "  "
  );
};

export const saveNewCredentials = (registration: IRegisteredCredentials) => {
  const registrations = getRegistrations();
  registrations.push(registration);
  setRegistrations(registrations);
};

export const getPermissionLable =(item:string,SUB_PERMISSION_TYPE :any)=>{
  const value = SUB_PERMISSION_TYPE.filter((data:any) => data.value === item)
  return value[0]?.label;
}

/* eslint-disable */
export const validateEmailString = (emailString :string) =>{
  const emailRegex =
				/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
			return  emailRegex.test(emailString);
    }
