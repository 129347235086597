import { atom } from "recoil";
import { IBasicInfoData } from "./types";

export const AddtionalInfoState = atom<string>({
  key: "addtion-Info-State",
  default: "basic-informations",
});

export const BasicInfoDataState = atom<IBasicInfoData>({
  key: "basic-info-data-state-key",
  default: {
    companyName: "",
    dba: "",
    einNumber: "",
    companyType: "",
    dateOfFormation: "",
    stateOfFormation: "",
    tcc: "",
    industrySector: "",
    companyFormationDocs: [],
    street: "",
    country: "",
    state: "",
    city: "",
    zip: "",
  },
});
