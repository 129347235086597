import { FC, useCallback, useEffect, useState } from 'react';
import { Button, ReactDropdown } from '@storybook';
import './add-member.scss';
import { useNetwork } from 'hooks';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MemberData, MemberListData } from 'states/memberstate';
import { SUB_PERMISSION_TYPE, USER_ROLE_TYPE } from '../constant';
import { getPermissionLable } from 'hooks/web-authentication/utils';
interface IModal {
	visible: boolean;
	handleClose: () => void;
	headingLabel: string;
}

interface IRolePermissions {
	firstSignatory: string;
	secondSignatory: string;
	equityPlanAward: string;
	convertibles: string;
	warrants: string;
}
interface IAddMemberData {
	firstName: string;
	lastName: string;
	email: string;
	role: string[];
	rolePermissions:IRolePermissions;
}

export const AddSignatoriesModal: FC<IModal> = ({
	visible,
	handleClose,
	headingLabel,
}) => {
	// global state
	const getMemeberData = useRecoilValue(MemberData);
	console.log(getMemeberData); //eslint-disable-line

	const SetMemberList = useSetRecoilState(MemberListData);

	// api state
	const { post: addMember } = useNetwork();
	const { get: getMemberList } = useNetwork();

	// local states
	const [addMemberData, setAddMemberData] = useState<IAddMemberData>({
		firstName: '',
		lastName: '',
		email: '',
		role: [headingLabel],
		rolePermissions:{
			firstSignatory:'',
			secondSignatory:'',
			equityPlanAward:'',
			convertibles:'',
			warrants:''
		}
	});

	const controlStyle = {
		minHeight: 42,
		minWidth: 180,
		height: 42,
		borderRadius: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.06)',
		'&:hover': { borderColor: 'black', cursor: 'pointer' },
		fontWeight: 500,
		fontSize: 14,
	};

	const optionStyle = {
		height: 42,
		minHeight: 42,
		'&:hover': { background: 'var(--color-black)', color: 'white' },
	};

	const getmembersdata = () => {
		getMemberList(`/user-role?role=${headingLabel}`).then((data) =>
			SetMemberList(data?.data)
		);
	};

	useEffect(() => {
		getmembersdata();
	}, []);

	if (!visible) {
		return null;
	}

	const handleAddClick = async () => {
		const payload = {
			...addMemberData,
		};
		await addMember(`/user-invite?role=${headingLabel}`, payload).finally(() => {
			handleClose();
			getmembersdata();
		});
	};

	const isDisabled = () => {
		return true;
	};

	const handleDropChange = useCallback(
		(item: { label: string; value: string }) => {
			setAddMemberData((prevState: any) => {
				let newState: any = { ...prevState };
				newState = item?.label;
				return newState;
			});
		},
		[addMemberData]
	);

	return (
		<div className="modal-main">
			<div className="modal-main__content">
				<div className="modal-main__heading-row">
					<div className="modal-main__label">{getPermissionLable(headingLabel,USER_ROLE_TYPE)}</div>
					<div className="modal-main__delete-icon">
						<i
							className="ri-close-circle-line"
							onClick={() => handleClose()}
						></i>
					</div>
				</div>
				<div className="form-body">
					<div className="form-body__row">
						<div className="form-body__half-space">
							<label className="signatories-modal-lable">
								Certificate (1st)
							</label>
							<ReactDropdown
								controlStyle={controlStyle}
								optionsStyle={optionStyle}
								handleChangeSelect={handleDropChange}
								options={SUB_PERMISSION_TYPE}
								getOptionLabel={(option: any) => (
									<div>
										<div>{option.label}</div>
										<div style={{ fontSize: '12px', color: '#7A7A7A' }}>
											{option.description}
										</div>
									</div>
								)}
								value={{
									label: 'Select',
									value: addMemberData,
								}}
								label="User"
								required
								// defaultValue={SUB_ROLE_TYPE[0]}
							/>
						</div>
						<div className="form-body__half-space">
							<label className="signatories-modal-lable">&nbsp;</label>
							<ReactDropdown
								controlStyle={controlStyle}
								optionsStyle={optionStyle}
								handleChangeSelect={handleDropChange}
								options={SUB_PERMISSION_TYPE}
								getOptionLabel={(option: any) => (
									<div>
										<div>{option.label}</div>
										<div style={{ fontSize: '12px', color: '#7A7A7A' }}>
											{option.description}
										</div>
									</div>
								)}
								value={{
									label: 'Select',
									value: addMemberData,
								}}
								label="User"
								required
								// defaultValue={SUB_ROLE_TYPE[0]}
							/>
						</div>
					</div>
					<div className="form-body__row">
						<div className="form-body__half-space">
							<label className="signatories-modal-lable">
								Certificate (2nd)
							</label>
							<ReactDropdown
								controlStyle={controlStyle}
								optionsStyle={optionStyle}
								handleChangeSelect={handleDropChange}
								options={SUB_PERMISSION_TYPE}
								getOptionLabel={(option: any) => (
									<div>
										<div>{option.label}</div>
										<div style={{ fontSize: '12px', color: '#7A7A7A' }}>
											{option.description}
										</div>
									</div>
								)}
								value={{
									label: 'Select',
									value: addMemberData,
								}}
								label="User"
								required
								// defaultValue={SUB_ROLE_TYPE[0]}
							/>
						</div>
						<div className="form-body__half-space">
							<label className="signatories-modal-lable">&nbsp;</label>
							<ReactDropdown
								controlStyle={controlStyle}
								optionsStyle={optionStyle}
								handleChangeSelect={handleDropChange}
								options={SUB_PERMISSION_TYPE}
								getOptionLabel={(option: any) => (
									<div>
										<div>{option.label}</div>
										<div style={{ fontSize: '12px', color: '#7A7A7A' }}>
											{option.description}
										</div>
									</div>
								)}
								value={{
									label: 'Select',
									value: addMemberData,
								}}
								label="User"
								required
								// defaultValue={SUB_ROLE_TYPE[0]}
							/>
						</div>
					</div>
					<div>
						<label className="signatories-modal-lable">
							Equity plan awards
						</label>
						<div
							style={{
								fontSize: '13px',
								fontWeight: 500,
								paddingBottom: '10px',
							}}
						>
							Permissions
							<span style={{ marginLeft: '5px', color: '#DE7935' }}>
								(Required)
							</span>
							<ReactDropdown
								controlStyle={controlStyle}
								optionsStyle={optionStyle}
								handleChangeSelect={handleDropChange}
								options={SUB_PERMISSION_TYPE}
								getOptionLabel={(option: any) => (
									<div>
										<div>{option.label}</div>
										<div style={{ fontSize: '12px', color: '#7A7A7A' }}>
											{option.description}
										</div>
									</div>
								)}
								value={{
									label: 'Select',
									value: addMemberData,
								}}
								// defaultValue={SUB_ROLE_TYPE[0]}
							/>
						</div>
					</div>
					<div>
						<label className="signatories-modal-lable">Convertibles</label>
						<div
							style={{
								fontSize: '13px',
								fontWeight: 500,
								paddingBottom: '10px',
							}}
						>
							Permissions
							<span style={{ marginLeft: '5px', color: '#DE7935' }}>
								(Required)
							</span>
							<ReactDropdown
								controlStyle={controlStyle}
								optionsStyle={optionStyle}
								handleChangeSelect={handleDropChange}
								options={SUB_PERMISSION_TYPE}
								getOptionLabel={(option: any) => (
									<div>
										<div>{option.label}</div>
										<div style={{ fontSize: '12px', color: '#7A7A7A' }}>
											{option.description}
										</div>
									</div>
								)}
								value={{
									label: 'Select',
									value: addMemberData,
								}}
								// defaultValue={SUB_ROLE_TYPE[0]}
							/>
						</div>
					</div>
					<div>
						<label className="signatories-modal-lable">Warrants</label>
						<div
							style={{
								fontSize: '13px',
								fontWeight: 500,
								paddingBottom: '10px',
							}}
						>
							Permissions
							<span style={{ marginLeft: '5px', color: '#DE7935' }}>
								(Required)
							</span>
							<ReactDropdown
								controlStyle={controlStyle}
								optionsStyle={optionStyle}
								handleChangeSelect={handleDropChange}
								options={SUB_PERMISSION_TYPE}
								getOptionLabel={(option: any) => (
									<div>
										<div>{option.label}</div>
										<div style={{ fontSize: '12px', color: '#7A7A7A' }}>
											{option.description}
										</div>
									</div>
								)}
								value={{
									label: 'Select',
									value: addMemberData,
								}}
								// defaultValue={SUB_ROLE_TYPE[0]}
							/>
						</div>
					</div>
				</div>
				<div className="add-member-footer">
					<div className="add-member-footer__right">
						<Button
							label={'Cancel'}
							width="100px"
							handleClick={() => handleClose()}
							type={'sumbit'}
							fontColor="black"
							backgroundcolor="#f0f4ff"
						/>
						<Button
							label={'Add Signatories'}
							width="100px"
							backgroundcolor="black"
							handleClick={() => handleAddClick()}
							type={'sumbit'}
							disabled={isDisabled()}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
