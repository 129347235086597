import { Header } from 'views';
import { DashboardBody } from './component/dashboard-body';

import './dashboard-page.scss';

export const DashboardPage = () => {
	return (
		<div className="dashboard-wrapper">
			<Header title="Dashboard" />
			<DashboardBody />
		</div>
	);
};
