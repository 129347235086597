import { ReactResponsiveTable } from '@storybook/react-responsive-table';
import Share from '../../../../assets/share.svg';
import './sharetable.scss';
import { FC, useEffect, useState } from 'react';
import { SHARE_TABLE_COLUMN, shareClassRowData } from './constant';

interface IShareTable {
    height: string;
}

export const Sharetable: FC<IShareTable> = ({ height }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, []);

    return (
        <>
            <div className="share-table-container" style={{ height }}>
                <div className="share-table-wrapper">
                    <div className="share-one">
                        <ReactResponsiveTable
                            isLoading={loading}
                            isLoaded
                            column={SHARE_TABLE_COLUMN.HEADER}
                            rows={shareClassRowData}
                            className="stake-one"
                            showSearch={true}
                            height="calc(100vh - 300px)"
                            emptyHeight={'calc(100vh - 255px)'}
                            columnHandle={true}
                            emptyIllustration={Share}
                            emptyDescription="No  Equity plan available"
                            headerbtnlable={'Draft Shares'}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
