import { FC, FocusEventHandler } from "react";
import Select from "react-select";

import "./dropdown.scss";

// type IOption = { label: string; value: any };
type IOption = any;

interface IReactDropdown {
  getOptionLabel?: any;
  label?: string;
  handleChangeSelect?: (event: IOption) => void;
  options: IOption[];
  value?: IOption;
  defaultValue?: IOption;
  isSearchable?: boolean;
  isMulti?: boolean;
  createStyle?: any;
  controlStyle?: any;
  menuStyle?: any;
  multiValueStyle?: any;
  multiValueRemoveStyle?: any;
  optionsStyle?: any;
  Isdisable?: boolean;
  placeholder?: string;
  handleBlur?: FocusEventHandler<HTMLInputElement>;
  required?: boolean;
}

export const ReactDropdown: FC<IReactDropdown> = ({
  label,
  handleChangeSelect,
  options,
  getOptionLabel,
  value,
  defaultValue,
  isSearchable = false,
  isMulti = false,
  createStyle,
  controlStyle = {},
  menuStyle = {},
  multiValueStyle = {},
  multiValueRemoveStyle = {},
  optionsStyle = {},
  Isdisable,
  
  placeholder = "Select",
  handleBlur,
  required = false,
}) => {
  const customStyle = {
    control: (styles: { [key: string]: number | string }) => ({
      ...styles,
      backgroundColor: "#0000000f",
      minHeight: 44,
      border: "1px solid rgba(215, 223, 245, 1)",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "2px solid #ced2de",

      borderRadius: 8,
      fontWeight: "500",
      color: "#fff",
      boxShadow: "none",
      "&:hover": {
        cursor: "pointer",
        borderBottom: "2px solid black",
         },
      "&:focus": {
        borderColor: "red",
      },
      ...controlStyle,
    }),
    menu: (styles: { [key: string]: any }) => ({
      ...styles,
      background: "#f5f8ff",
      borderRadius: 0,
      border: "1px solid #ced2de",
      zIndex: 3,
      ...menuStyle,
    }),
    multiValue: (styles: { [key: string]: any }) => ({
      ...styles,
      backgroundColor: "rgba(224, 233, 255, 1)",
      color: "rgba(47, 50, 61, 1)",
      borderRadius: 50,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 4,
      paddingBottom: 4,
      margin: 4,
      ...multiValueStyle,
    }),

    multiValueRemove: (styles: any) => ({
      ...styles,
      color: "rgba(163, 177, 214, 1)",
      borderRadius: 50,
      marginLeft: 2,
      fontSize: 12,
      ...multiValueRemoveStyle,
    }),
    option: (styles: { [key: string]: any }) => {
      return {
        ...styles,
        backgroundColor: "#f5f8ff",
        color: "#000",
        height: 52,
        cursor: "pointer",
        paddingTop: 12,
        paddingBottom: getOptionLabel ? 60 : 12,
        borderBottom: "1px solid #ced2de",
        fontWeight: "500",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "&:hover": {
          color: "#fff",
          backgroundColor: "black",
        },
        "&:last-child": {
          borderBottom: "none",
        },
        ...optionsStyle,
      };
    },
    valueContainer: (provided: any) => ({
      ...provided,
      textOverflow: "ellipsis",
      overflowY: "scroll",
      display: "flex",
      maxHeight: "130px",
      borderRadius: 0,
    }),
  };

  return (
		<div>
			<div className="dropdown-label">
				{label}
				{required && 
        <span className="input__label-required">
          (Required)
        </span>}
			</div>
			<Select
				isDisabled={Isdisable}
				onBlur={handleBlur}
				defaultValue={defaultValue}
				isMulti={isMulti}
				value={value}
				placeholder={placeholder}
				className="dropdown"
				styles={createStyle ? createStyle : customStyle}
				options={options}
				getOptionLabel={getOptionLabel}
				isClearable={false}
				closeMenuOnSelect={!isMulti}
				isSearchable={isSearchable}
				onChange={handleChangeSelect as any}
				components={{
					IndicatorSeparator: () => null,
				}}
			/>
		</div>
	);
};
