import { FC, useCallback, useState, useEffect } from 'react';
import { Button, Input, Loader } from '@storybook';
import './add-member.scss';
import { useNetwork } from 'hooks';
import { useSetRecoilState } from 'recoil';
import { MemberListData } from 'states/memberstate';
import { getPermissionLable, validateEmailString } from 'hooks/web-authentication/utils';
import { USER_ROLE_TYPE, USER_TYPE } from '../constant';
import { API_URL } from 'constant';
interface IModal {
	visible: boolean;
	handleClose: () => void;
	headingLabel: string;
	getModalData: any;
}

interface IRolePermissions {
	lawFirmName: string;
}
interface IAddMemberData {
	firstName: string;
	lastName: string;
	email: string;
	role: string[];
	status: string;
	rolePermissions: IRolePermissions;
}

export const AddLawFirmMember: FC<IModal> = ({
	visible,
	handleClose,
	headingLabel,
	getModalData
}) => {
	const SetMemberList = useSetRecoilState(MemberListData);

	// api state
	const { post: addMember, loading:addMemberLoading } = useNetwork();
	const { get: getMemberList } = useNetwork();
	const { patch: updateMemberData, loading:updateMemberLoading } = useNetwork();

	// local states
	const [addMemberData, setAddMemberData] = useState<IAddMemberData>({
		firstName: getModalData?.firstName ?? '',
		lastName: getModalData?.lastName ?? '',
		email: getModalData?.email ?? '',
		role: [headingLabel],
		status:'Not yet registered',
		rolePermissions: {
			lawFirmName: getModalData?.rolePermissions?.lawFirmName ?? '',
		},
	});
	const [error, setError] = useState(false);
	const [isloading, setIsLoading] = useState(false);

	const getmembersdata = () => {
		getMemberList(`/user-role?role=${headingLabel}`).then((data) =>
			SetMemberList(data?.data)
		);
	};

	if (!visible) {
		return null;
	}
	/* eslint-disable */
	const handleChangeData = useCallback(
		(type: string, e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setAddMemberData((prevState) => {
				const newState: any = { ...prevState };
				if (type === 'lawFirmName') {
					newState.rolePermissions = {
						...newState.rolePermissions,
						[type]: value,
					};
				} else {
					newState[type] = value;
				}
				return newState;
			});
			const isValidEmail = validateEmailString(addMemberData.email);
			if (type === 'email') {
				if (isValidEmail) {
					setError(false);
				} else {
					setError(true);
				}
			}
		},
		[addMemberData,error]
	);

	const handleAddClick = async () => {
		setIsLoading(true);
		const payload = {
			...addMemberData,
		};
		{!getModalData && await addMember(`${API_URL.USER_INVITE_ROLE}${headingLabel}`, payload).finally(() => {
			getmembersdata();
		})};

		{getModalData && await updateMemberData(`${API_URL.USERS}/${getModalData._id}`, {
			firstName: addMemberData.firstName,
			lastName: addMemberData.lastName,
			email:addMemberData.email,
			role: [USER_TYPE.LAW_FIRM],
			lawFirmName: addMemberData.rolePermissions.lawFirmName,
		}).finally(() => {
			getmembersdata();
		})};
	};

	useEffect(() => {
		if(isloading && addMemberLoading===false && updateMemberLoading===false) {
			setIsLoading(false);
			handleClose();
		}
	}, [updateMemberLoading,addMemberLoading,isloading])

	const isDisabled = () => {
		const isValidEmail = validateEmailString(addMemberData.email);
		const isValidData =
			addMemberData.firstName &&
			addMemberData.lastName &&
			isValidEmail &&
			addMemberData.rolePermissions.lawFirmName;
		const isDataMatch =
			addMemberData.firstName !== getModalData?.firstName ||
			addMemberData.lastName !== getModalData?.lastName ||
			addMemberData.email !== getModalData?.email ||
			addMemberData.rolePermissions.lawFirmName !==
				getModalData?.rolePermissions.lawFirmName;
		if (getModalData ? isDataMatch && isValidData : isValidData) {
			return false;
		}
		return true;
	};

	const renderButtonLable = useCallback(() => {
		return (
			<>
				{isloading ? (
					<Loader className="loader-blue" type="loader" dimension={16} />
				) : getModalData ? (
					'Update'
				) : (
					'Invite'
				)}
			</>
		);
	}, [isloading]);

	return (
		<div className="modal-main">
			<div className="modal-main__content">
				<div className="modal-main__heading-row">
					<div className="modal-main__label">{getPermissionLable(headingLabel,USER_ROLE_TYPE)}</div>
					<div className="modal-main__delete-icon">
						<i
							className="ri-close-circle-line"
							onClick={() => handleClose()}
						></i>
					</div>
				</div>
				<div className="form-body">
					<div className="form-body__row">
						<div className="form-body__half-space">
							<Input
								handleChange={(event) => handleChangeData('firstName', event)}
								inputType={'text'}
								placeholder={'First Name'}
								label="First Name"
								value={addMemberData?.firstName}
								required
							/>
						</div>
						<div className="form-body__half-space">
							<Input
								inputType={'text'}
								placeholder={'Last Name'}
								label="Last Name"
								handleChange={(event) => handleChangeData('lastName', event)}
								value={addMemberData?.lastName}
								required
							/>
						</div>
					</div>
					<div className="form-body__margin-top">
						<Input
							inputType={'text'}
							placeholder={'Email'}
							label="Email"
							handleChange={(event) => handleChangeData('email', event)}
							value={addMemberData?.email}
							isError={error}
							required
						/>
					</div>
					<div className="form-body__margin-top">
						<Input
							inputType={'text'}
							placeholder={'Law Firm Name'}
							label="Law Firm Name"
							handleChange={(event) => handleChangeData('lawFirmName', event)}
							value={addMemberData?.rolePermissions?.lawFirmName}
							required
						/>
					</div>
				</div>
				<div className="add-member-footer">
					<div className="add-member-footer__right">
						<Button
							label={'Cancel'}
							width="100px"
							handleClick={() => handleClose()}
							type={'sumbit'}
							fontColor="black"
							backgroundcolor="#f0f4ff"
						/>
						<Button
							label={renderButtonLable()}
							width="100px"
							backgroundcolor="black"
							handleClick={() => handleAddClick()}
							type={'sumbit'}
							disabled={isDisabled()}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
