import { useCallback } from "react";
import { useRecoilValue } from "recoil";

import { AddtionalInfoState } from "./store";
import { BankInformation } from "./bank-information";
import { BasicInformationScreen } from "./basic-information";

export const AdditionalInformation = () => {
  const AddtionInfoState = useRecoilValue(AddtionalInfoState);

  const handleScreens = useCallback(() => {
    if (AddtionInfoState === "basic-informations") {
      return <BasicInformationScreen />;
    } else return <BankInformation />;
  }, [AddtionInfoState]);

  return <div>
{handleScreens()}
</div>;
};
