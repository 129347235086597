import { atom } from 'recoil';

export const ActiveComponentType = atom<JSX.Element | null>({
	key: 'invite-investor-component-key',
	default: null,
});

export const OpenInviteInvestorModal = atom({
	key: 'invite-investor-open-modal-key',
	default: false,
});

export const OpenInviteSelectorModal = atom<boolean>({
	key: 'open-invite-selector-modal',
	default: false,
});

export const CSVDataState = atom<any>({
	key: 'csv-data-key',
	default: [],
});

export const UploadedCSVState = atom<any[]>({
	key: 'uploaded-csv-key',
	default: [],
});

export const PipelineIdState = atom<string>({
	key: 'pipeline-id-investor-key',
	default: '',
});

export const InvestorTableGridData = atom<any>({
	key: 'cap-grid-table',
	default: [
		[
			{ value: 'Investor First Name', readOnly: true },
			{ value: 'Investor Last Name', readOnly: true },
			{ value: 'Investor Entity', readOnly: true },
			{ value: 'Investor Address', readOnly: true },
			{ value: 'Investor Country Code', readOnly: true },
			{ value: 'Investor Mobile', readOnly: true },
			{ value: 'Investors Email', readOnly: true },
			{ value: 'Number of Shares', readOnly: true },
			{ value: 'Price per Share', readOnly: true },
			{ value: 'Dollars Invested', readOnly: true },
			{ value: 'Share Class', readOnly: true },
			{ value: 'Issue Date', readOnly: true },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
	],
});

export const InvestorTableGridDataHeader = atom({
	key: 'cap-grid-table-dublicate',
	default: [
		[
			{ value: 'Investor First Name', readOnly: true },
			{ value: 'Investor Last Name', readOnly: true },
			{ value: 'Investor Entity', readOnly: true },
			{ value: 'Investor Address', readOnly: true },
			{ value: 'Investor Country Code', readOnly: true },
			{ value: 'Investor Mobile', readOnly: true },
			{ value: 'Investors Email', readOnly: true },
			{ value: 'Number of Shares', readOnly: true },
			{ value: 'Price per Share', readOnly: true },
			{ value: 'Dollars Invested', readOnly: true },
			{ value: 'Share Class', readOnly: true },
			{ value: 'Issue Date', readOnly: true },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
	],
});
export const GridCAPTableData = atom<any>({
	key: 'invite-table-grid',
	default: {},
});

export const SelectedInviteFundState = atom<{ label: string; value: string }>({
	key: 'selected-invite-fund-state',
	default: {
		label: '',
		value: '',
	},
});

export const SelectedFundType = atom<'create' | 'edit'>({
	key: 'selected-fund-type',
	default: 'create',
});

export const currentCapTableData = atom<any>({
	key: 'current-cap-table',
	default: [
		[
			{ value: 'Investor First Name', readOnly: true },
			{ value: 'Investor Last Name', readOnly: true },
			{ value: 'Investor Entity', readOnly: true },
			{ value: 'Investor Address', readOnly: true },
			{ value: 'Investor Country Code', readOnly: true },
			{ value: 'Investor Mobile', readOnly: true },
			{ value: 'Investors Email', readOnly: true },
			{ value: 'Number of Shares', readOnly: true },
			{ value: 'Price per Share', readOnly: true },
			{ value: 'Dollars Invested', readOnly: true },
			{ value: 'Share Class', readOnly: true },
			{ value: 'Issue Date', readOnly: true },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
	],
});

export type IInviteNavigateModalSteps =
	| 'create'
	| 'csv'
	| 'signAggrement'
	| 'connectBank'
	| 'configCaptable'
	| 'billing'
	| 'selectBankFLow'
	| 'personalForm'
	| 'businessForm'
	| 'accountProcessing'
	| 'escrowSuccess'
	| 'reminderNotification';

export const InviteNavigateModalState = atom<IInviteNavigateModalSteps>({
	key: 'invite-navigate-modal-state',
	default: 'create',
});

export const IsCreatingFundState = atom<boolean>({
	key: 'is-creating-fund-state',
	default: false,
});

export const IsCapTableLoading = atom<boolean>({
	key: 'is-capTable-loading',
	default: false,
});

export const SelectedPipelineDetails = atom<any>({
	key: 'selected-pipeline-details',
	default: {},
});

export const ShowSelectedField = atom<string[]>({
	key: 'show-selected-field',
	default: [],
});

export const RecipientBankAccountLoadingState = atom<boolean>({
	key: 'recipient-bank-account-loading',
	default: false,
});

export const SelectedBankFlowState = atom<string>({
	key: 'select-bank-flow-state',
	default: '',
});
export type IInviteShareNavigateModalStateSteps =
	| 'addshareclass'
	| 'sharemodal'
	| 'sharesheet';

export const InviteShareNavigateModalState =
	atom<IInviteShareNavigateModalStateSteps>({
		key: 'invite-share-navigate-modal-state',
		default: 'addshareclass',
	});
