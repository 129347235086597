export const USER_ROLE_TYPE = [
    {
      label: "Administration",
      value: "ADMINISTRATION",
    },
    {
      label: "Board Members",
      value: "BOARD_MEMBERS",
    },
    {
      label: "Law Firm",
      value: "LAW_FIRM",
    },
    {
      label: "Signatories",
      value: "SIGNATORIES",
    },
  ];

  export const USER_TYPE = {
    ADMINISTRATION :"ADMINISTRATION",
    BOARD_MEMBERS :"BOARD_MEMBERS",
    LAW_FIRM : "LAW_FIRM",
    SIGNATORIES : "SIGNATORIES"
  }

  export const SUB_ROLE_TYPE = [
    {
      label: "Director",
      value: "Director",
      description:"Board director can join meetings, view and sign resolutions."
    },
    {
      label: "Observer",
      value: "Observer",
      description:"Board director can join meetings and view resolutions."
    },
  ];

  export const SUB_PERMISSION_TYPE = [
    {
      label: "Legal Administrator",
      value: "Legal_administrator",
      description:"Description"
    },
    {
      label: "Company Editor",
      value: "Company_editor",
      description:"Description"
    },
    {
      label: "HR Administrator",
      value: "HR_administrator",
      description:"Description"
    },
    {
      label: "Company Viewer",
      value: "Company_viewer",
      description:"Description"
    },
   
  ];

export const ADMINISTRATORS_COLOUMN = [
  {
    label: "Name",
    key: "name",
    format: "string",
    width: "15%",
  },

  {
    label: "Email",
    key: "email",
    key2: "contact",
    format: "jsx",
    width: "20%",
    className: "email__contact",
  },
  {
    label: "Title",
    key: "title",
    format: "string",
    width: "15%",
  },
  {
    label: "Permissions",
    key: "permissions",
    format: "jsx",
    width: "15%",
  },
  {
    label: "Status",
    key: "status",
    format: "jsx",
    width: "15%",
  },
  {
    label: ' ',
    key: 'action',
    format: 'jsx',
    width: '3%',
  },
];  

export const ROLE_PERMISSION_COLOUMN = [
  {
    label: "Name",
    key: "name",
    format: "string",
    width: "15%",
  },
	{
		label: 'Email',
		key: 'email',
		key2: 'contact',
		format: 'jsx',
		width: '20%',
		className: 'email__contact',
	},
	{
		label: 'Role',
		key: 'role',
		format: 'string',
		width: '15%',
	},
	{
		label: 'Start Date',
		key: 'startdate',
		key2: 'date',
		format: 'jsx',
		width: '15%',
	},
	{
		label: 'End Date',
		key: 'enddate',
		key2: 'date',
		format: 'jsx',
		width: '15%',
	},
	{
		label: 'Status',
		key: 'status',
		format: 'jsx',
		width: '15%',
	},
	{
		label: ' ',
		key: 'action',
		format: 'jsx',
		width: '3%',
	},
];

export const LAW_FIRM_COLOUMN = [
  {
    label:"Name",
    key:'name',
    format:'string',
    width:'25%'
  },
  {
    label:"Email",
    key:'email',
    format:'string',
    width:'25%'
  },
  {
    label:"Law firm name",
    key:'lawFirmName',
    format:'string',
    width:'25%'
  },
  {
    label: "Status",
    key: "status",
    format: "jsx",
    width: "22%",
  },
  {
    label: ' ',
    key: 'action',
    format: 'jsx',
    width: '3%',
  },
]

export const SIGNATORIES_COLOUMN = [
	{
		label: 'Document',
		key: 'document',
		format: 'string',
		width: '40%',
	},
	{
		label: 'Signatory',
		key: 'signatory',
		format: 'string',
		width: '30%',
	},
	{
		label: 'Date assigned',
		key: 'dateAssigned',
		format: 'string',
		width: '30%',
	},
];

export const signatoriesRowData = [
	{
		signatoriesId: 'firstSig',
		certificateId: 1,
		certificateName: 'Certificate (1st Signatory)',
    certificateKey: 'signatoryOne',
		signatoriesName: '',
		dateAssign: '',
    userId:''
	},
	{
		signatoriesId: 'secondSig',
		certificateId: 2,
		certificateName: 'Certificate (2nd Signatory)',
    certificateKey: 'signatorySecond',
		signatoriesName: '',
		dateAssign: '',
    userId:''
	},
	{
		signatoriesId: 'equitySig',
		certificateId: 3,
		certificateName: 'Equity plan awards',
    certificateKey: 'equityPlanAward',
		signatoriesName: '',
		dateAssign: '',
    userId:''
	},
	{
		signatoriesId: 'convertiblesSig',
		certificateId: 4,
		certificateName: 'Convertibles',
		certificateKey: 'convertibles',
    signatoriesName: '',
		dateAssign: '',
    userId:''
	},
	{
		signatoriesId: 'warrentsSig',
		certificateId: 5,
		certificateName: 'Warrants',
    certificateKey: 'warrents',
		signatoriesName: '',
		dateAssign: '',
    userId:''
	},
];
