import { FC } from "react";

import { Image } from "@storybook/image";
interface ITableEmptyMessage {
  height: string;
  image: string;
  description:string;
}

export const TableEmptyMessage: FC<ITableEmptyMessage> = ({ height,image ,description}) => {
  return (
    <div className="empty-table" style={{ height }}>
      <Image url={image } className="empty-table__image"  />
      <div className="share-empty-text">{description}</div>
    </div>
  );
};
