import { Header } from "views";
import { DataRoomsBody } from "./components/data-rooms-body";

export const DataRooms = () => {

  return (
    <div className="dashboard-wrapper">
      <Header title="Data Rooms" />
      <DataRoomsBody />
    </div>
  );
};
