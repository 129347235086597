export const API_URL = {
  VERIFY_EMAIL: "/verify-email",
  SIGN_UP: "/email-otp",
  WebAuthLogin: "/web-auth-login",
  ADD_BASIC_INFORMATION: "/url/carta/information",
  STATE_CITY: "/country-state-city",
  ROLES_PERMISSION:"/user-role?role=",
  SIGNATORY:"/signatory",
  USER_INVITE:"/user-invite",
  USER_INVITE_ROLE:"/user-invite?role=",
  USERS:"/users/",
  USER_ROLE: '/user-role?type=boolean',
  DATA_ROOM: '/data-room',
};
