import { useCallback, useMemo, useState } from 'react';
import { Button } from '@storybook';
import Modal from '@storybook/modal';
import { Header } from 'views/header';
import { ShareSheet } from '../share-sheet';
import { ShareModal } from '../share-popup';
import { Sharetable } from '../share-class';
import { ShareTableEmptyMessage } from '../share-empty-message/Shareemptymessage';
import { shareClassRowData } from '../share-class/constant';

import './share-empty.scss';

type IbuttonPrevTitle = 'Cancel' | 'Back';
type IbuttonNextTitle = 'Submit' | 'Next';
interface IModal {
	title: string;
	component: JSX.Element;
	buttonNextTitle: IbuttonNextTitle;
	buttonPrevTitle: IbuttonPrevTitle;
	className: string;
}
export const ShareEmpty = () => {
	const [open, setOpen] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(0);

	const allComponent = [
		{
			title: 'Share Modal ',
			component: <ShareModal />,
			buttonNextTitle: 'Next',
			buttonPrevTitle: 'Cancel',
			className: 'Share class',
		},
		{
			title: 'Share Sheet ',
			component: <ShareSheet />,
			buttonNextTitle: 'Submit',
			buttonPrevTitle: 'Back',
			className: 'share-sheet-modal',
		},
	];

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setCurrentIndex(0);
	};

	const { title, component, buttonPrevTitle, buttonNextTitle, className } =
		useMemo(() => {
			return allComponent[currentIndex] as IModal;
		}, [currentIndex]);

	const handleModalClick = useCallback(
		(type: string) => {
			if (type === 'next' && currentIndex < allComponent.length - 1) {
				setCurrentIndex((prev) => prev + 1);
			} else if (currentIndex > 0 && type === 'prev') {
				setCurrentIndex((prev) => prev - 1);
			} else {
				handleClose();
			}
		},
		[currentIndex, allComponent]
	);
	const isEmpty = useMemo(() => {
		if (shareClassRowData.length > 0) return false;
		return true;
	}, [shareClassRowData.length]);

	return (
		<>
			<Modal
				modalName="Share class"
				title={title}
				closeModal={() => setOpen(false)}
				isOpen={open}
				optionalClassName={className}
			>
				{component}

				<div className="footer-wraper-share">
					<div className="footer-share">
						<Button
							width="80px"
							backgroundcolor="rgba(0, 0, 0, 0.06)"
							fontColor="black"
							borderRadius="4px"
							label={buttonPrevTitle}
							fontSize="16px"
							type={'submit'}
							handleClick={() => handleModalClick('prev')}
						/>
						<Button
							width="80px"
							borderRadius="4px"
							backgroundcolor="black"
							fontColor="white"
							border="1px solid #a3b1d6"
							fontSize="16px"
							label={buttonNextTitle}
							type={''}
							handleClick={() => handleModalClick('next')}
						/>
					</div>
				</div>
			</Modal>
			<div className="share-container">
				<Header title="Shares" />
				<div className="equity">
					<div className="dashboard-body-shares">
						<div className="dashbody-inner">
							<div className="btn-add-new-share">
								<Button
									label={'Add new share class'}
									width="128px"
									backgroundcolor="black"
									type={'sumbit'}
									borderRadius="4px"
									handleClick={() => handleClick()}
								/>
							</div>
						</div>

						{isEmpty ? (
							<ShareTableEmptyMessage />
						) : (
							<Sharetable height={'250px'} />
						)}
					</div>
				</div>
			</div>
		</>
	);
};
