export const BANKS = [
  {
    bankName: "Bank account (Instant) ACH",
    description: "Some Description",
  },
  {
    bankName: "Bank account (Manual) ACH",
    description: "Some Description",
  },
  {
    bankName: "Wire transfer account",
    description: "Some Description",
  },
];

export const companyTypes = [
  {
    label: "Private  Company",
    value: "Private  Company",
  },
  {
    label: "Public Company.",
    value: "Public Company.",
  },
  {
    label: "Section 8 Company (NGO)",
    value: "Section 8 Company (NGO)",
  },
  {
    label: "Micro Companies.",
    value: "Micro Companies.",
  },
  {
    label: "By Shares.",
    value: "By Shares.",
  },
];

export const INDUSTRY_SECTOR = [
  {
    label: "Pharmaceuticals",
    value: "Pharmaceuticals",
  },
  {
    label: "Technology Hardware, Storage and Peripherals",
    value: "Technology Hardware, Storage and Peripherals",
  },
  {
    label: "Entertainment and Media",
    value: "Entertainment and Media",
  },
  {
    label: "CRM Software",
    value: "CRM Software",
  },
  {
    label: "Medical Devices",
    value: "Medical Devices",
  },
  {
    label: "Real Estate Development and Services",
    value: "Real Estate Development and Services",
  },
  {
    label: "Consumer Electronics",
    value: "Consumer Electronics",
  },
  {
    label: "Crypto/Web3",
    value: "Crypto/Web3",
  },
  {
    label: "General SaaS",
    value: "General SaaS",
  },
  {
    label: "Renewable Energy",
    value: "Renewable Energy",
  },
  {
    label: "Video Game Software",
    value: "Video Game Software",
  },
  {
    label: "IT Consulting",
    value: "IT Consulting",
  },
  {
    label: "Advertising and Advertising Tech",
    value: "Advertising and Advertising Tech",
  },
  {
    label: "Food Products",
    value: "Food Products",
  },
  {
    label: "Biotech",
    value: "Biotech",
  },
  {
    label: "Semiconductors and Semiconductor Equipment",
    value: "Semiconductors and Semiconductor Equipment",
  },
  {
    label: "Marketplace",
    value: "Marketplace",
  },
  {
    label: "Apparel",
    value: "Apparel",
  },
  {
    label: "Healthcare Software and Tech",
    value: "Healthcare Software and Tech",
  },
  {
    label: "Research and Consulting Services",
    value: "Research and Consulting Services",
  },
  {
    label: "Wireless Telecommunication Services",
    value: "Wireless Telecommunication Services",
  },
  {
    label: "Household and Personal Products",
    value: "Household and Personal Products",
  },
  {
    label: "Financial Exchanges and Data",
    value: "Financial Exchanges and Data",
  },
  {
    label: "Cybersecurity",
    value: "Cybersecurity",
  },
  {
    label: "Education",
    value: "Education",
  },
  {
    label: "Payment Processing",
    value: "Payment Processing",
  },
  {
    label: "Financial Software",
    value: "Financial Software",
  },
  {
    label: "Lending",
    value: "Lending",
  },
  {
    label: "Data Analytics",
    value: "Data Analytics",
  },
  {
    label: "Logistics and Supply Chain Software",
    value: "Logistics and Supply Chain Software",
  },
  {
    label: "Cannabis",
    value: "Cannabis",
  },
  {
    label: "Recruiting",
    value: "Recruiting",
  },
  {
    label: "Transportation",
    value: "Transportation",
  },
  {
    label: "Social Media and Networking Platforms",
    value: "Social Media and Networking Platforms",
  },
  {
    label: "Internet and Direct Marketing Retail",
    value: "Internet and Direct Marketing Retail",
  },
  {
    label: "ERP and HR Software",
    value: "ERP and HR Software",
  },
];

export const SUPPORTED_COUNTRIES = [
  {
    label: "United States",
    value: "United States",
  },
  {
    label: "Afghanistan",
    value: "Afghanistan",
  },
  {
    label: "Aland Islands",
    value: "Aland Islands",
  },
  {
    label: "American Samoa",
    value: "American Samoa",
  },
  {
    label: "Andorra",
    value: "Andorra",
  },
  {
    label: "Albania",
    value: "Albania",
  },
  {
    label: "Anguilla",
    value: "Anguilla",
  },
  {
    label: "Antarctica",
    value: "Antarctica",
  },
  {
    label: "Antigua And Barbuda",
    value: "Antigua And Barbuda",
  },
  {
    label: "Algeria",
    value: "Algeria",
  },
  {
    label: "Argentina",
    value: "Argentina",
  },
  {
    label: "Armenia",
    value: "Armenia",
  },
  {
    label: "Angola",
    value: "Angola",
  },
  {
    label: "Aruba",
    value: "Aruba",
  },
  {
    label: "Australia",
    value: "Australia",
  },
  {
    label: "Austria",
    value: "Austria",
  },
  {
    label: "Azerbaijan",
    value: "Azerbaijan",
  },
  {
    label: "Bahrain",
    value: "Bahrain",
  },
  {
    label: "Bangladesh",
    value: "Bangladesh",
  },
  {
    label: "Barbados",
    value: "Barbados",
  },
  {
    label: "Belarus",
    value: "Belarus",
  },
  {
    label: "Belgium",
    value: "Belgium",
  },
  {
    label: "Belize",
    value: "Belize",
  },
  {
    label: "Benin",
    value: "Benin",
  },
  {
    label: "Bermuda",
    value: "Bermuda",
  },
  {
    label: "Bhutan",
    value: "Bhutan",
  },
  {
    label: "Bolivia",
    value: "Bolivia",
  },
  {
    label: "Bonaire, Sint Eustatius and Saba",
    value: "Bonaire, Sint Eustatius and Saba",
  },
  {
    label: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina",
  },
  {
    label: "Botswana",
    value: "Botswana",
  },
  {
    label: "Bouvet Island",
    value: "Bouvet Island",
  },
  {
    label: "Brazil",
    value: "Brazil",
  },
  {
    label: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory",
  },
  {
    label: "Brunei",
    value: "Brunei",
  },
  {
    label: "Bulgaria",
    value: "Bulgaria",
  },
  {
    label: "Burkina Faso",
    value: "Burkina Faso",
  },
  {
    label: "Burundi",
    value: "Burundi",
  },
  {
    label: "Cambodia",
    value: "Cambodia",
  },
  {
    label: "Cameroon",
    value: "Cameroon",
  },
  {
    label: "Canada",
    value: "Canada",
  },
  {
    label: "Cape Verde",
    value: "Cape Verde",
  },
  {
    label: "Cayman Islands",
    value: "Cayman Islands",
  },
  {
    label: "Central African Republic",
    value: "Central African Republic",
  },
  {
    label: "Chad",
    value: "Chad",
  },
  {
    label: "Chile",
    value: "Chile",
  },
  {
    label: "China",
    value: "China",
  },
  {
    label: "Christmas Island",
    value: "Christmas Island",
  },
  {
    label: "Cocos (Keeling) Islands",
    value: "Cocos (Keeling) Islands",
  },
  {
    label: "Colombia",
    value: "Colombia",
  },
  {
    label: "Comoros",
    value: "Comoros",
  },
  {
    label: "Congo",
    value: "Congo",
  },
  {
    label: "Cook Islands",
    value: "Cook Islands",
  },
  {
    label: "Costa Rica",
    value: "Costa Rica",
  },
  {
    label: "Cote D'Ivoire (Ivory Coast)",
    value: "Cote D'Ivoire (Ivory Coast)",
  },
  {
    label: "Croatia",
    value: "Croatia",
  },
  {
    label: "Cuba",
    value: "Cuba",
  },
  {
    label: "Curaçao",
    value: "Curaçao",
  },
  {
    label: "Cyprus",
    value: "Cyprus",
  },
  {
    label: "Democratic Republic of the Congo",
    value: "Democratic Republic of the Congo",
  },
  {
    label: "Czech Republic",
    value: "Czech Republic",
  },
  {
    label: "Denmark",
    value: "Denmark",
  },
  {
    label: "Djibouti",
    value: "Djibouti",
  },
  {
    label: "Dominica",
    value: "Dominica",
  },
  {
    label: "Dominican Republic",
    value: "Dominican Republic",
  },
  {
    label: "East Timor",
    value: "East Timor",
  },
  {
    label: "Ecuador",
    value: "Ecuador",
  },
  {
    label: "Egypt",
    value: "Egypt",
  },
  {
    label: "El Salvador",
    value: "El Salvador",
  },
  {
    label: "Equatorial Guinea",
    value: "Equatorial Guinea",
  },
  {
    label: "Eritrea",
    value: "Eritrea",
  },
  {
    label: "Ethiopia",
    value: "Ethiopia",
  },
  {
    label: "Estonia",
    value: "Estonia",
  },
  {
    label: "Falkland Islands",
    value: "Falkland Islands",
  },
  {
    label: "Faroe Islands",
    value: "Faroe Islands",
  },
  {
    label: "Fiji Islands",
    value: "Fiji Islands",
  },
  {
    label: "Finland",
    value: "Finland",
  },
  {
    label: "France",
    value: "France",
  },
  {
    label: "Gambia The",
    value: "Gambia The",
  },
  {
    label: "French Guiana",
    value: "French Guiana",
  },
  {
    label: "French Polynesia",
    value: "French Polynesia",
  },
  {
    label: "Gabon",
    value: "Gabon",
  },
  {
    label: "French Southern Territories",
    value: "French Southern Territories",
  },
  {
    label: "Georgia",
    value: "Georgia",
  },
  {
    label: "Germany",
    value: "Germany",
  },
  {
    label: "Ghana",
    value: "Ghana",
  },
  {
    label: "Gibraltar",
    value: "Gibraltar",
  },
  {
    label: "Greece",
    value: "Greece",
  },
  {
    label: "Greenland",
    value: "Greenland",
  },
  {
    label: "Grenada",
    value: "Grenada",
  },
  {
    label: "Guadeloupe",
    value: "Guadeloupe",
  },
  {
    label: "Guam",
    value: "Guam",
  },
  {
    label: "Guatemala",
    value: "Guatemala",
  },
  {
    label: "Guernsey and Alderney",
    value: "Guernsey and Alderney",
  },
  {
    label: "Guinea",
    value: "Guinea",
  },
  {
    label: "Guinea-Bissau",
    value: "Guinea-Bissau",
  },
  {
    label: "Guyana",
    value: "Guyana",
  },
  {
    label: "Haiti",
    value: "Haiti",
  },
  {
    label: "Heard Island and McDonald Islands",
    value: "Heard Island and McDonald Islands",
  },
  {
    label: "Honduras",
    value: "Honduras",
  },
  {
    label: "Hong Kong S.A.R.",
    value: "Hong Kong S.A.R.",
  },
  {
    label: "Hungary",
    value: "Hungary",
  },
  {
    label: "Iceland",
    value: "Iceland",
  },
  {
    label: "India",
    value: "India",
  },
  {
    label: "Indonesia",
    value: "Indonesia",
  },
  {
    label: "Iran",
    value: "Iran",
  },
  {
    label: "Iraq",
    value: "Iraq",
  },
  {
    label: "Ireland",
    value: "Ireland",
  },
  {
    label: "Jamaica",
    value: "Jamaica",
  },
  {
    label: "Israel",
    value: "Israel",
  },
  {
    label: "Italy",
    value: "Italy",
  },
  {
    label: "Japan",
    value: "Japan",
  },
  {
    label: "Jersey",
    value: "Jersey",
  },
  {
    label: "Jordan",
    value: "Jordan",
  },
  {
    label: "Kazakhstan",
    value: "Kazakhstan",
  },
  {
    label: "Kenya",
    value: "Kenya",
  },
  {
    label: "Kiribati",
    value: "Kiribati",
  },
  {
    label: "Kosovo",
    value: "Kosovo",
  },
  {
    label: "Kuwait",
    value: "Kuwait",
  },
  {
    label: "Kyrgyzstan",
    value: "Kyrgyzstan",
  },
  {
    label: "Laos",
    value: "Laos",
  },
  {
    label: "Latvia",
    value: "Latvia",
  },
  {
    label: "Lebanon",
    value: "Lebanon",
  },
  {
    label: "Lesotho",
    value: "Lesotho",
  },
  {
    label: "Liberia",
    value: "Liberia",
  },
  {
    label: "Libya",
    value: "Libya",
  },
  {
    label: "Liechtenstein",
    value: "Liechtenstein",
  },
  {
    label: "Lithuania",
    value: "Lithuania",
  },
  {
    label: "Luxembourg",
    value: "Luxembourg",
  },
  {
    label: "Macau S.A.R.",
    value: "Macau S.A.R.",
  },
  {
    label: "Madagascar",
    value: "Madagascar",
  },
  {
    label: "Malawi",
    value: "Malawi",
  },
  {
    label: "Malaysia",
    value: "Malaysia",
  },
  {
    label: "Maldives",
    value: "Maldives",
  },
  {
    label: "Mali",
    value: "Mali",
  },
  {
    label: "Malta",
    value: "Malta",
  },
  {
    label: "Man (Isle of)",
    value: "Man (Isle of)",
  },
  {
    label: "Marshall Islands",
    value: "Marshall Islands",
  },
  {
    label: "Martinique",
    value: "Martinique",
  },
  {
    label: "Mauritania",
    value: "Mauritania",
  },
  {
    label: "Mauritius",
    value: "Mauritius",
  },
  {
    label: "Mayotte",
    value: "Mayotte",
  },
  {
    label: "Mexico",
    value: "Mexico",
  },
  {
    label: "Micronesia",
    value: "Micronesia",
  },
  {
    label: "Moldova",
    value: "Moldova",
  },
  {
    label: "Monaco",
    value: "Monaco",
  },
  {
    label: "Mongolia",
    value: "Mongolia",
  },
  {
    label: "Montenegro",
    value: "Montenegro",
  },
  {
    label: "Montserrat",
    value: "Montserrat",
  },
  {
    label: "Morocco",
    value: "Morocco",
  },
  {
    label: "Mozambique",
    value: "Mozambique",
  },
  {
    label: "Myanmar",
    value: "Myanmar",
  },
  {
    label: "Namibia",
    value: "Namibia",
  },
  {
    label: "Nauru",
    value: "Nauru",
  },
  {
    label: "Nepal",
    value: "Nepal",
  },
  {
    label: "Netherlands",
    value: "Netherlands",
  },
  {
    label: "New Caledonia",
    value: "New Caledonia",
  },
  {
    label: "New Zealand",
    value: "New Zealand",
  },
  {
    label: "Nicaragua",
    value: "Nicaragua",
  },
  {
    label: "Niger",
    value: "Niger",
  },
  {
    label: "Nigeria",
    value: "Nigeria",
  },
  {
    label: "Niue",
    value: "Niue",
  },
  {
    label: "Norfolk Island",
    value: "Norfolk Island",
  },
  {
    label: "North Macedonia",
    value: "North Macedonia",
  },
  {
    label: "North Korea",
    value: "North Korea",
  },
  {
    label: "Northern Mariana Islands",
    value: "Northern Mariana Islands",
  },
  {
    label: "Oman",
    value: "Oman",
  },
  {
    label: "Norway",
    value: "Norway",
  },
  {
    label: "Pakistan",
    value: "Pakistan",
  },
  {
    label: "Palau",
    value: "Palau",
  },
  {
    label: "Palestinian Territory Occupied",
    value: "Palestinian Territory Occupied",
  },
  {
    label: "Panama",
    value: "Panama",
  },
  {
    label: "Papua new Guinea",
    value: "Papua new Guinea",
  },
  {
    label: "Peru",
    value: "Peru",
  },
  {
    label: "Paraguay",
    value: "Paraguay",
  },
  {
    label: "Pitcairn Island",
    value: "Pitcairn Island",
  },
  {
    label: "Philippines",
    value: "Philippines",
  },
  {
    label: "Poland",
    value: "Poland",
  },
  {
    label: "Qatar",
    value: "Qatar",
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico",
  },
  {
    label: "Portugal",
    value: "Portugal",
  },
  {
    label: "Reunion",
    value: "Reunion",
  },
  {
    label: "Romania",
    value: "Romania",
  },
  {
    label: "Russia",
    value: "Russia",
  },
  {
    label: "Rwanda",
    value: "Rwanda",
  },
  {
    label: "Saint Helena",
    value: "Saint Helena",
  },
  {
    label: "Saint Kitts And Nevis",
    value: "Saint Kitts And Nevis",
  },
  {
    label: "Saint Lucia",
    value: "Saint Lucia",
  },
  {
    label: "Saint Pierre and Miquelon",
    value: "Saint Pierre and Miquelon",
  },
  {
    label: "Saint Vincent And The Grenadines",
    value: "Saint Vincent And The Grenadines",
  },
  {
    label: "Saint-Barthelemy",
    value: "Saint-Barthelemy",
  },
  {
    label: "Saint-Martin (French part)",
    value: "Saint-Martin (French part)",
  },
  {
    label: "Samoa",
    value: "Samoa",
  },
  {
    label: "San Marino",
    value: "San Marino",
  },
  {
    label: "Sao Tome and Principe",
    value: "Sao Tome and Principe",
  },
  {
    label: "Saudi Arabia",
    value: "Saudi Arabia",
  },
  {
    label: "Senegal",
    value: "Senegal",
  },
  {
    label: "Serbia",
    value: "Serbia",
  },
  {
    label: "Seychelles",
    value: "Seychelles",
  },
  {
    label: "Sierra Leone",
    value: "Sierra Leone",
  },
  {
    label: "Singapore",
    value: "Singapore",
  },
  {
    label: "Sint Maarten (Dutch part)",
    value: "Sint Maarten (Dutch part)",
  },
  {
    label: "Slovakia",
    value: "Slovakia",
  },
  {
    label: "Slovenia",
    value: "Slovenia",
  },
  {
    label: "Solomon Islands",
    value: "Solomon Islands",
  },
  {
    label: "Somalia",
    value: "Somalia",
  },
  {
    label: "South Africa",
    value: "South Africa",
  },
  {
    label: "South Georgia",
    value: "South Georgia",
  },
  {
    label: "South Korea",
    value: "South Korea",
  },
  {
    label: "South Sudan",
    value: "South Sudan",
  },
  {
    label: "Spain",
    value: "Spain",
  },
  {
    label: "Sri Lanka",
    value: "Sri Lanka",
  },
  {
    label: "Sudan",
    value: "Sudan",
  },
  {
    label: "Suriname",
    value: "Suriname",
  },
  {
    label: "Svalbard And Jan Mayen Islands",
    value: "Svalbard And Jan Mayen Islands",
  },
  {
    label: "Swaziland",
    value: "Swaziland",
  },
  {
    label: "Sweden",
    value: "Sweden",
  },
  {
    label: "Switzerland",
    value: "Switzerland",
  },
  {
    label: "Syria",
    value: "Syria",
  },
  {
    label: "Taiwan",
    value: "Taiwan",
  },
  {
    label: "Tajikistan",
    value: "Tajikistan",
  },
  {
    label: "Tanzania",
    value: "Tanzania",
  },
  {
    label: "Thailand",
    value: "Thailand",
  },
  {
    label: "The Bahamas",
    value: "The Bahamas",
  },
  {
    label: "Togo",
    value: "Togo",
  },
  {
    label: "Tokelau",
    value: "Tokelau",
  },
  {
    label: "Tonga",
    value: "Tonga",
  },
  {
    label: "Trinidad And Tobago",
    value: "Trinidad And Tobago",
  },
  {
    label: "Tunisia",
    value: "Tunisia",
  },
  {
    label: "Turkey",
    value: "Turkey",
  },
  {
    label: "Turkmenistan",
    value: "Turkmenistan",
  },
  {
    label: "Turks And Caicos Islands",
    value: "Turks And Caicos Islands",
  },
  {
    label: "Tuvalu",
    value: "Tuvalu",
  },
  {
    label: "Uganda",
    value: "Uganda",
  },
  {
    label: "Ukraine",
    value: "Ukraine",
  },
  {
    label: "United Arab Emirates",
    value: "United Arab Emirates",
  },
  {
    label: "United Kingdom",
    value: "United Kingdom",
  },
  {
    label: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands",
  },
  {
    label: "Uruguay",
    value: "Uruguay",
  },
  {
    label: "Uzbekistan",
    value: "Uzbekistan",
  },
  {
    label: "Vanuatu",
    value: "Vanuatu",
  },
  {
    label: "Vatican City State (Holy See)",
    value: "Vatican City State (Holy See)",
  },
  {
    label: "Venezuela",
    value: "Venezuela",
  },
  {
    label: "Vietnam",
    value: "Vietnam",
  },
  {
    label: "Virgin Islands (British)",
    value: "Virgin Islands (British)",
  },
  {
    label: "Virgin Islands (US)",
    value: "Virgin Islands (US)",
  },
  {
    label: "Wallis And Futuna Islands",
    value: "Wallis And Futuna Islands",
  },
  {
    label: "Yemen",
    value: "Yemen",
  },
  {
    label: "Western Sahara",
    value: "Western Sahara",
  },
  {
    label: "Zambia",
    value: "Zambia",
  },
  {
    label: "Zimbabwe",
    value: "Zimbabwe",
  },
];

export const COMPANY_DETAILS = [
  {
    key: "companyName",
    label: "Company name",
    required: true,
    type: "text",
  },
  {
    key: "dba",
    label: "DBA (Doing business as)",
    required: false,
    type: "text",
  },
  {
    key: "einNumber",
    label: "EIN/Tax ID Number",
    required: true,
    type: "text",
  },
  {
    key: "companyType",
    label: "Company Type",
    required: true,
    type: "dropdown",
    values: companyTypes,
  },
  {
    key: "dateOfFormation",
    label: "Date of Formation",
    required: false,
    type: "date",
  },
  {
    key: "stateOfFormation",
    label: "State Of Formation",
    required: true,
    type: "dropdown",
  },
  {
    key: "tcc",
    label: "TCC (Transmitter control code)",
    required: false,
    type: "text",
  },
  {
    key: "industrySector",
    label: "Industry Sector",
    required: true,
    type: "dropdown",
    values: INDUSTRY_SECTOR,
  },
  {
    key: "companyFormationDocs",
    label: "Company Formation Document",
    required: true,
    type: "file",
  },
];

export const COMPANY_ADDRESS = [
  {
    key: "street",
    label: "Street/Locality",
    required: true,
    type: "text",
  },
  {
    key: "country",
    label: "Country",
    required: true,
    type: "dropdown",
    values: SUPPORTED_COUNTRIES,
  },

  {
    key: "state",
    label: "State",
    required: true,
    type: "dropdown",
    values: [],
  },
  {
    key: "city",
    label: "City",
    required: true,
    type: "dropdown",
    values: [],
  },
  {
    key: "zip",
    label: "ZIP code",
    required: true,
    type: "text",
  },
];

export const controlStyle = {
  minHeight: 42,
  minWidth: 180,
  height: 42,
  borderRadius: 0,
  backgroundColor: "rgba(0, 0, 0, 0.06)",
  "&:hover": { borderColor: "black", cursor: "pointer" },
  fontWeight: 500,
  fontSize: 14,
};

export const optionStyle = {
  height: "max-content",
  minHeight: 42,
  "&:hover": { background: "var(--color-black)", color: "white" },
};

export const BASIC_INFO = [
  {
    wrapper: "company-details",
    label: "Company details",
    desc: "Some Description",
    icon: "ri-building-line",
    key: "companyDetails",
  },
  {
    wrapper: "company-address",
    label: "Company Address",
    desc: "Some Description",
    icon: "ri-map-pin-line",
    key: "companyAddress",
  },
];

export const isRequired = {
  companyName: " Company Name",
  einNumber: " EIN Number",
  companyType: " Company Type",
  stateOfFormation: " State of formation",
  industrySector: " Industry Sector",
  street: " Street",
  country: " Country",
  state: " State",
  city: " City",
  zip: " Zip Code"
}
