import { atom } from "recoil";

export const MemberData = atom<any>({
    key: "member-data-State",
    default: "",
});

export const MemberListData = atom<any>({
    key: "member-list-data-State",
    default: "",
});

export const MemberListSearchText = atom<string>({
    key: "member-list-Search-Text",
    default: "",
});

export const ModalButtonLable = atom<any>({
    key: "modal-button-lable-State",
    default: "Add Member",
});
  