import { ReactResponsiveTable } from '@storybook/react-responsive-table';
import { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import {
	USER_ROLE_TYPE,
	ROLE_PERMISSION_COLOUMN,
	ADMINISTRATORS_COLOUMN,
	SUB_PERMISSION_TYPE,
	LAW_FIRM_COLOUMN,
	USER_TYPE,
	signatoriesRowData,
	SIGNATORIES_COLOUMN,
} from '../constant';
import './role-permission-body.scss';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MemberListData } from 'states/memberstate';
import { useNetwork } from 'hooks';
import {
	AddAdministrationMember,
	AddBoardMember,
	AddLawFirmMember,
} from '../member-Modal';
import { AddSignatoriesModal } from '../member-Modal/add-signatories-modal';
import { GetAdminLoginUserId, selectedUserTypeRoleState } from 'states';
import { getDate } from 'utils';
import { getPermissionLable } from 'hooks/web-authentication/utils';
import { API_URL } from 'constant';


export const RolePermissionBody = () => {
	const [selectedUserTypeRole, setSelectedUserTypeRole] = useRecoilState(
		selectedUserTypeRoleState
	);
	const ref = useRef<HTMLDivElement>(null);
	const [selectOptionData, setSelectOptionData] = useState<any>();
	const [optionName, setOptionName] = useState<any>([]);
	const [optionData, setOptionData] = useState<any>('');

	const [userData, setUserdata] = useState<any>();
	const [adminData, setAdminData] = useState<any>([]);
	const [modalData, setModaldata] = useState<any>();
	const [dropOption, setDropOption] = useState(false);
	const [dropId, setDropId] = useState(false);
	const [tableColumn, setTableColumn] = useState<any>(ADMINISTRATORS_COLOUMN);
	const [userListRow, setUserListRows] = useState([]);
	const [showAddMemberModal, setShowAddMemberModal] = useState<boolean>(false);
	const getMemeberData = useRecoilValue(MemberListData);
	const adminLoginUserId = useRecoilValue(GetAdminLoginUserId);
	const {
		get: getMemberList,
		isLoaded: memberListLoaded,
		loading: memberListLoading,
	} = useNetwork();
	const { get: getSignotoriesData } = useNetwork();
	const { post: reInviteMember } = useNetwork();
	const { post: postSignotoriesData } = useNetwork();
	const { post: updateSignotoriesData, data: getSignatoryResponse } =
		useNetwork();

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				setOptionData('');
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);
	


	useEffect(() => {
		if (selectedUserTypeRole === USER_TYPE.ADMINISTRATION) {
			setTableColumn(ADMINISTRATORS_COLOUMN);
		} else if (selectedUserTypeRole === USER_TYPE.BOARD_MEMBERS) {
			setTableColumn(ROLE_PERMISSION_COLOUMN);
		} else if (selectedUserTypeRole === USER_TYPE.LAW_FIRM) {
			setTableColumn(LAW_FIRM_COLOUMN);
		} else if (selectedUserTypeRole === USER_TYPE.SIGNATORIES) {
			setTableColumn(SIGNATORIES_COLOUMN);
		}
	}, [selectedUserTypeRole, tableColumn]);

	const mapUserTypesRole = useMemo(() => {
		return USER_ROLE_TYPE.map((type) => (
			<div
				key={type.value}
				onClick={() => setSelectedUserTypeRole(type.value)}
				className={`role-box__user-role-left-user-types ${selectedUserTypeRole === type.value ? '--user-role-active' : ''
					}`}
			>
				{type.label}
			</div>
		));
	}, [selectedUserTypeRole]);

	const handleAddnewModal = useCallback(() => {
		setShowAddMemberModal(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setModaldata('');
		setShowAddMemberModal(false);
	}, []);

	useEffect(() => {
		if (selectedUserTypeRole !== USER_TYPE.SIGNATORIES) {
			getMemberList(`${API_URL.ROLES_PERMISSION}${selectedUserTypeRole}`).then(
				(data) => {
					const newData = data?.data?.map((item: any) => {
						return { ...item };
					});
					setUserdata(newData);
					if (selectedUserTypeRole === USER_TYPE.ADMINISTRATION) {
						return setAdminData(newData);
					}
				}
			);
		} else if (selectedUserTypeRole === USER_TYPE.SIGNATORIES) {
			getMemberList(
				`${API_URL.ROLES_PERMISSION}${USER_TYPE.ADMINISTRATION}`
			).then((data) => {
				const newData = data?.data?.map((item: any) => {
					return { ...item };
				});
				setUserdata(signatoriesRowData);
				setAdminData(newData);
			});
		}
	}, [getMemeberData, selectedUserTypeRole]);

	const handleDropChange = useCallback(
		(item: any) => {
			userData.map((ele: any) => {
				if (ele._id === item._id) {
					setDropOption(!dropOption);
				}
			});
		},
		[dropOption, userData]
	);
	const getSignatoryData = () => {
		getSignotoriesData(`${API_URL.SIGNATORY}?userId=${adminLoginUserId}`).then(
			(data) => {
				setSelectOptionData(data?.data);
			}
		);
	};

	useEffect(() => {
		getSignatoryData();
	}, [selectedUserTypeRole]);

	useEffect(() => {
		if (getSignatoryResponse) {
			const signatoryData = signatoriesRowData.map((data: any) => {
				if (getSignatoryResponse[data.signatoriesId]) {
					data.signatoriesName =
						getSignatoryResponse[data.signatoriesId]?.fullName;
					data.dateAssign = getDate(
						getSignatoryResponse[data.signatoriesId]?.assignedDate
					);
					data.userId = getSignatoryResponse[data.signatoriesId]?.user;
				}
				return data;
			});
			setUserdata(signatoryData);
		}
	}, [getSignatoryResponse]);

	const getSignatoriesData = (field: string) => {
		const optionData = selectOptionData?.find((x: any) => x[field]);
		return {
			name: optionData?.[field]?.fullName || 'Select',
			userid: optionData?.[field]?.user,
			documentid: optionData?._id,
			assignedDate: optionData?.[field]?.assignedDate,
			updatedAt: optionData?.updatedAt ?? '--',
		};
	};

	const selectOption = (
		id: number,
		data: any,
		document: string,
		signatoryID: string
	) => {
		const signatotyData = getSignatoriesData(signatoryID);
		if (!signatotyData) {
			postSignotoriesData(`${API_URL.SIGNATORY}`, {
				[document]: data._id,
				userId: adminLoginUserId,
			});
		} else {
			updateSignotoriesData(`${API_URL.SIGNATORY}`, {
				[document]: data._id,
				signatoryId: getSignatoriesData(signatoryID)?.documentid,
				userId: adminLoginUserId,
			});
		}
		setOptionData('');
		const name = `${data.firstName}  ${data.lastName}`;
		const userId = data._id;
		const duplicateIndex = optionName.findIndex((data: any) => data.id === id);
		if (duplicateIndex === -1) {
			// Add new option if ID doesn't exist
			setOptionName([...optionName, { id, name, userId }]);
		} else {
			// Replace existing option value with new value
			const updatedOptionName = [...optionName];
			updatedOptionName[duplicateIndex].name = name;
			updatedOptionName[duplicateIndex].userId = userId;
			setOptionName(updatedOptionName);
		}
		getSignatoryData();
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				setDropOption(false);
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [ref]);

	const renderModal = (item: any) => {
		setModaldata(item);
		setShowAddMemberModal(true);
		setDropOption(false);
	};

	const handleResendInvite = useCallback(
		(userData: any) => {
			const payload = {
				firstName: userData?.firstName,
				lastName: userData?.lastName,
				email: userData?.email,
				role: [selectedUserTypeRole],
			};
			reInviteMember(`${API_URL.USER_INVITE}`, payload);
			setDropOption(false);
		},
		[dropOption]
	);

	const filterAdminIds = () => {
		const signatoriesData = signatoriesRowData.filter(
			(x) => x.certificateId <= 2
		);
		return signatoriesData.map(
			(data) => getSignatoriesData(data.signatoriesId)?.userid
		);
	};

	const filterDropOptionData = () => {
		const existAdminId = filterAdminIds();
		existAdminId.push(
			userData
				.filter((data: any) => data.certificateId <= 2 && data.userId !== '')
				.map((data: any) => data.userId)
		);
		return adminData.filter((admin: any) => !existAdminId.includes(admin._id));
	};

	useEffect(() => {
		if (tableColumn.length) {
			const rows: any = [];
			userData?.forEach((item: any, index: number) => {
				if (item) {
					const row: any = {};
					tableColumn?.forEach(({ key }: any) => {
						const renderStatus = () => {
							return (
								<div className="status-wrapper">
									<div
										className={
											item?.status === 'Active'
												? 'active-icon'
												: 'inactive-icon'
										}
									></div>
									<p>
										{item?.status}
									</p>
								</div>
							);
						};
						if (key === 'status') {
							row.status =  renderStatus();
						}

						const renderAction = () => {
							return (
								<div>
									<i
										className="ri-more-2-line"
										style={{
											fontSize: '18px',
										}}
										onClick={() => {
											handleDropChange(item);
											setDropId(item._id);
										}}
									/>
									{dropOption && item._id === dropId && (
										<div className="dropoption-wrapper" ref={ref}>
											<div
												className="dropoption-section"
												onClick={() => renderModal(item)}
											>
												<i
													className="ri-edit-line"
													style={{
														fontSize: '18px',
													}}
												/>
												Edit
											</div>
											<div
												className="dropoption-section"
												onClick={() => handleResendInvite(item)}
											>
												<i
													className="ri-restart-line"
													style={{
														fontSize: '18px',
													}}
												/>
												Resend invite
											</div>
										</div>
									)}
								</div>
							);
						};
						const isSignatory = item?.certificateName
							?.toLowerCase()
							?.includes('signatory');

						const renderDropOptions = (data: any) => (
							<div key={data.name} className="roles-dropOptions">
								<div>
									<div
										style={{ margin: '4px', padding: '5px' }}
										onClick={() =>
											selectOption(
												index,
												data,
												item?.certificateKey,
												item?.signatoriesId
											)
										}
									>
										<div>
											<div
												style={{ textTransform: 'capitalize' }}
											>
												{`${data.firstName}  ${data.lastName}`}
											</div>

											<div className="role-dropOptions-select-data">
												{data?.rolePermissions?.titles?.join(', ')}
											</div>
										</div>
									</div>
								</div>
							</div>
						);

						const signatoryDropOption = () => {
							return (
								<div>
									<div
										style={{ display: 'flex', cursor: 'pointer', maxWidth:'200px' }}
										onClick={() =>
											setOptionData(
												optionData?.length == 0 ? item?.certificateId : ''
											)
										}
									>
										<div>
											<span style={{ textTransform: 'capitalize' }}>
												{getSignatoriesData(item.certificateKey)
													? item.signatoriesName ||
													getSignatoriesData(item.signatoriesId).name : 'Select'}
											</span>
										</div>
										<div style={{ marginLeft: 'auto' }}>
											<i
												onClick={() =>
													setOptionData(
														optionData.length == 0 ? item?.certificateId : ''
													)
												}
												style={{ color: '#7A7A7A' }}
												className="ri-arrow-down-s-fill"
											></i>
										</div>
									</div>
									{optionData === item?.certificateId && (
										<div className="roles-dropOptions-wrapper" ref={ref}>
											{isSignatory
												? filterDropOptionData()?.map(renderDropOptions)
												: adminData?.map(renderDropOptions)}
										</div>
									)}
								</div>
							);
						};

						if (key === 'action') {
							row.action = renderAction();
						}

						row.name = `${item?.firstName} ${item?.lastName}`;
						row.email =  item?.email;
						const startDate = item?.rolePermissions?.startDate?.split('T')[0];
						const endDate = item?.rolePermissions?.endDate?.split('T')[0];
						row.startdate = startDate && getDate(startDate);
						row.enddate = endDate && getDate(endDate);
						row.title =  item?.rolePermissions?.titles.join(',');
						row.permissions =  getPermissionLable(
							item?.rolePermissions?.permissions,
							SUB_PERMISSION_TYPE
						);
						row.role = item?.rolePermissions?.boardMemberRole;
						row.lawFirmName = item?.rolePermissions?.lawFirmName;
						row.document = item.certificateName;
						const dateAssign = getSignatoriesData(
							item?.signatoriesId
						)?.assignedDate?.split('T')[0];
						row.dateAssigned = dateAssign?.length ? getDate(dateAssign) : dateAssign
						if (key == 'signatory') {
							row.signatory =  signatoryDropOption();
						}
					});
					rows.push(row);
				}
			});
			return setUserListRows(rows);
		}
	}, [
		userData,
		dropOption,
		dropId,
		selectedUserTypeRole,
		tableColumn,
		optionData,
		adminData,
	]);

	const renderMemberDataTable = useMemo(() => {
		
		return (
			<ReactResponsiveTable
				column={tableColumn}
				rows={userListRow}
				height="calc(100vh - 300px)"
				emptyHeight={'calc(100vh - 255px)'}
				handleAddNewClick={handleAddnewModal}
				headerbtnlable="Add Member"
				isButton={selectedUserTypeRole !== USER_TYPE.SIGNATORIES}
				isLoading={memberListLoading}
				
				loaderType='skeleton'
			/>
		);
	}, [userListRow, memberListLoading, memberListLoaded, userData, optionData]);

	return (
		<div className="role-permission-wrapper">
			<div className="role-box">
				<div className="role-box__user-role-left">
					{mapUserTypesRole}
				</div>
				<div className="role-box__user-role-right">
					<div className="role-box__right-body-wrapper">
						{renderMemberDataTable}
					</div>
				</div>
			</div>
			{/* Modal for adding new Member for the selected user */}
			{showAddMemberModal &&
				selectedUserTypeRole === USER_TYPE.ADMINISTRATION && (
					<AddAdministrationMember
						handleClose={() => handleCloseModal()}
						visible={showAddMemberModal}
						headingLabel={selectedUserTypeRole}
						getModalData={modalData}
					/>
				)}
			{showAddMemberModal &&
				selectedUserTypeRole === USER_TYPE.BOARD_MEMBERS && (
					<AddBoardMember
						handleClose={() => handleCloseModal()}
						visible={showAddMemberModal}
						headingLabel={selectedUserTypeRole}
						getModalData={modalData}
					/>
				)}
			{showAddMemberModal && selectedUserTypeRole === USER_TYPE.LAW_FIRM && (
				<AddLawFirmMember
					handleClose={() => handleCloseModal()}
					visible={showAddMemberModal}
					headingLabel={selectedUserTypeRole}
					getModalData={modalData}
				/>
			)}
			{showAddMemberModal && selectedUserTypeRole === USER_TYPE.SIGNATORIES && (
				<AddSignatoriesModal
					handleClose={() => handleCloseModal()}
					visible={showAddMemberModal}
					headingLabel={selectedUserTypeRole}
				/>
			)}
		</div>
	);
};
