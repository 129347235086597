export const ROUTES = {
	LOG_IN: '/login',
	DASHBOARD: '/dashboard',
	SETUP: '/setup',
	NO_ROUTE: '*',
	SIGN_UP: '/sign-up',
	BASIC_INFORMATION: '/basic-information',
	DATA_ROOMS: '/data-rooms',
	FINANCIALS: 'financials',
	TAXES: '/role-permission',
	SETTINGS: '/settings',
	SHARES: '/shares',
	OPTIONS: '/options',
	WARRANT: '/warrant',
	CONVERTiBLES: '/convertibles',
	TEMPLATES: '/templates',
	STAKEHOLDER: '/stakeholder',
	SHARE_CLASS: '/share-Class',
	EQUITY_PLAN: '/equity-Plan',
	SIMPLICI_STAKEHOLDER: '/simplici-stakeholder',
};

export const ACTIVE_TABS = {
	SETUP: 'setup',
	DASHBOARD: 'dashboard',
	DATA_ROOMS: 'data-rooms',
	API: 'api',
	ANALYTICS: 'analytics',
	SIMPLICI_SIGN: 'simplici-sign',
	FINANCIALS: 'financials',
	TAXES: 'role-permission',
	SETTINGS: 'Settings',
	SHARES: 'Shares',
	OPTIONS: 'options',
	WARRANT: 'Warrant',
	CONVERTiBLES: 'convertibles',
	TEMPLATES: 'templates',
	STAKEHOLDER: 'Stakeholder',
	SHARE_CLASS: 'share-class',
	EQUITY_PLAN: 'equity-plan',
};

export const SIDEBAR_TABS = {
	label: 'Main Menu',
	tabs: [
		{
			ROUTE: ACTIVE_TABS.DASHBOARD,
			TITLE: 'Company',
			ICON: 'ri-list-check-2',
			TAB_INDEX: 1,
			SUB_ROUTE: [
				{
					ROUTE: ACTIVE_TABS.SETUP,
					TITLE: 'Setup',
					ICON: 'ri-list-settings-line',
				},
				{
					ROUTE: ACTIVE_TABS.DASHBOARD,
					TITLE: 'Dashboard',
					ICON: 'ri-home-5-line',
				},
				{
					ROUTE: ACTIVE_TABS.FINANCIALS,
					TITLE: 'Financials',
					ICON: 'ri-wallet-3-line',
				},
				{
					ROUTE: ACTIVE_TABS.TAXES,
					TITLE: 'Roles & Permission',
					ICON: 'ri-user-settings-line',
				},
				{
					ROUTE: ACTIVE_TABS.DATA_ROOMS,
					TITLE: 'Data Rooms',
					ICON: 'ri-increase-decrease-line',
				},

				{
					ROUTE: ACTIVE_TABS.SETTINGS,
					TITLE: 'Settings',
					ICON: 'ri-settings-line',
				},
			],
		},
		{
			ROUTE: ACTIVE_TABS.SIMPLICI_SIGN,
			TITLE: 'Equity Inst.',
			ICON: 'ri-draft-line',
			TAB_INDEX: 15,
			SUB_ROUTE: [
				{
					ROUTE: ACTIVE_TABS.SHARES,
					TITLE: 'Shares',
					ICON: 'ri-pie-chart-2-line',
				},
				{
					ROUTE: ACTIVE_TABS.OPTIONS,
					TITLE: 'Options',
					ICON: 'ri-bar-chart-line',
				},
				{
					ROUTE: ACTIVE_TABS.WARRANT,
					TITLE: 'Warrant',
					ICON: 'ri-article-line',
				},
				{
					ROUTE: ACTIVE_TABS.CONVERTiBLES,
					TITLE: 'Convertibles',
					ICON: 'ri-draft-line',
				},
				{
					ROUTE: ACTIVE_TABS.EQUITY_PLAN,
					TITLE: 'Equity Plan',
					ICON: 'ri-compasses-2-fill',
				},
			],
		},
		{
			ROUTE: ACTIVE_TABS.DATA_ROOMS,
			TITLE: 'Capitalization Table',
			ICON: 'ri-hand-coin-line',
			TAB_INDEX: 2,
			SUB_ROUTE: [
				{
					ROUTE: ACTIVE_TABS.STAKEHOLDER,
					TITLE: 'Stakeholder',
					ICON: 'ri-group-line',
				},
				{
					ROUTE: ACTIVE_TABS.SHARE_CLASS,
					TITLE: 'Share Class',
					ICON: 'ri-pantone-line',
				},
			],
		},
	],
};
