import { atom } from 'recoil';

export const routeState = atom<boolean>({
	key: 'route-state',
	default: true,
});

export const iFrameState = atom<boolean>({
	key: 'iframe-state',
	default: false,
});

export const selectedUserTypeRoleState = atom<string>({
	key: 'selected-User-Type-Role-state',
	default: 'ADMINISTRATION',
});
