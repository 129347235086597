import { Button } from '@storybook';
import equity from '../../../../assets/equity.svg';

import { useState } from 'react';
import { Equitydetail } from '../equity-detail/equitydetails';
import Modal from '@storybook/modal';

import './equityplan.scss';

export const Equityplan = () => {
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Modal
				modalName="Equity Plan"
				title="Equity plan"
				closeModal={handleClose}
				optionalClassName="equity-plan-modal"
				className="equityplan"
				isOpen={open}
				
			>
				{' '}
				<Equitydetail />
			</Modal>
			<div className="equity">
				<div className="dashboard-equity-body">
					<div className="dashbody-inner-equity">
						<div className="btn-add-new">
							<Button
								label={'Add new plan'}
								width="120px"
								backgroundcolor="black"
								handleClick={() => handleClick()}
								type={'sumbit'}
								borderRadius="4px"
							/>
						</div>
					</div>
					<div className="empty-equity">
						<img src={equity} alt="group" />
						<div>
							<div className="no-equity-text">No equity plan available</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
