export const DATA_ROOMS_TYPE = [
	{
		lebel: 'Files',
		value: 'files',
	},
	{
		lebel: 'Acticity Log',
		value: 'activity-log',
	},
];

export const DATA_ROOMS_COLOUMN = [
	{
		label: 'Name',
		key: 'name',
		format: 'string',
		width: '50%',
	},
	{
		label: 'Items',
		key: 'items',
		format: 'string',
		width: '20%',
	},
	{
		label: 'Last Modified',
		key: 'lastmodified',
		format: 'string',
		width: '25%',
	},
	{
		label: 'Actions',
		key: 'action',
		format: 'string',
		width: '5%',
	},
];

export const DEFAULT_DATA_ROOMS_FOLDER = [
	'Company documents',
	'Company financials',
	'Board documents',
	'Board meetings',
	'Board notifications',
	'Investor updates',
];

export const MAX_FILE_SIZE = "52428800" //50mb
export const MAX_FILE_COUNT = 10; //10 file

