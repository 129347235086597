import { useCallback, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ToastContainer } from 'react-toastify';
import { AdditionalInformation, DashBoard } from 'views';
import { useCookie } from 'hooks';
import { ROUTES } from './routes/index';
import { IsBasicInformationDone, WebTokenState, GetAdminLoginUserId, iFrameState } from 'states';
import 'react-toastify/dist/ReactToastify.css';
import 'remixicon/fonts/remixicon.css';
import { Stakeholder } from 'views/sidebar/stakeholder/stakeholder';

const { DASHBOARD, NO_ROUTE, BASIC_INFORMATION, SIMPLICI_STAKEHOLDER } = ROUTES;

const App = () => {
	const { get: getCookie } = useCookie();
	const localUserData = getCookie('user');
	const [basicInformationDone, setBasicInformationDone] = useRecoilState(
		IsBasicInformationDone
	);
	const [IframeState, setIframeState] = useRecoilState(iFrameState);
	const setSimpliciToken = useSetRecoilState(WebTokenState);
	const setAdminLoginUserId = useSetRecoilState(GetAdminLoginUserId);

	const handleDashboardRoute = useCallback(() => {
		if (localUserData?.IsBasicInfoDone || basicInformationDone) {
			return <DashBoard />;
		}
		return <AdditionalInformation />;
	}, [basicInformationDone, localUserData?.IsBasicInfoDone]);

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const token = queryParams.get('token');
		const userId = queryParams.get('loginUserId');
		if (token !== null) {
			setSimpliciToken(token);
			setAdminLoginUserId(userId);
		}
	}, []);

	useEffect(() => {
		const isIframe = window.self !== window.top;
		if (isIframe) {
			setIframeState(true);
			setBasicInformationDone(true);
		} else {
			setIframeState(false);
		}
	}, []);

	return (
		<div className="App">
			<ToastContainer></ToastContainer>
			<BrowserRouter>
				<Routes>
					<>
						<Route path={NO_ROUTE} element={handleDashboardRoute()} />
						<Route path={NO_ROUTE} element={<Navigate to={DASHBOARD} />} />
						<Route
							path={BASIC_INFORMATION}
							element={<AdditionalInformation />}
						/>
						{IframeState && <Route path={SIMPLICI_STAKEHOLDER} element={<Stakeholder />} />}
					</>
				</Routes>
			</BrowserRouter>
		</div>
	);
};

export default App;
