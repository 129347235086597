import { FC, useMemo } from 'react';
import './uploadfiles.scss';

interface IUploadedFiles {
	files: any[];
	handleDeleteFile: any;
	icon?: any;
}

export const UplaodedFiles: FC<IUploadedFiles> = ({
	files,
	handleDeleteFile,
}) => {
	const mapFiles = useMemo(() => {
		return (
			files.length > 0 &&
			/* eslint-disable */
			files.map((file, i) => (
				<div key={i} className="uploaded-file-box__row">
					<div className="uploaded-file-box__filetype">
						<i className="ri-file-list-3-line"></i>
					</div>
					<div className="uploaded-file-box__row-innerbox">
						<div className="uploaded-file-box__fileName">{file.name}</div>
						<div
							onClick={() => handleDeleteFile(file)}
							className="uploaded-file-box__delete-icon"
						>
							<i className="ri-delete-bin-6-line"></i>
						</div>
					</div>
				</div>
			))
		);
	}, [files, handleDeleteFile]);

	return (
		<div className="uploaded-file-box">
			<div className="uploaded-file-box__heading">Files Uploaded</div>
			{mapFiles}
		</div>
	);
};
