
import Share  from '../../../../assets/share.svg';

export const ShareTableEmptyMessage = () => {
	return (
		<div className="empty-share">
			<img src={Share } alt="group" />

			<div>
				<div className="no-equity-text"> No equity plan available</div>
			</div>
		</div>
	);
};
