import { useCallback } from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { IframePropsState, IsOpenInIframeState } from "./store";

export const useNotification = () => {
  const { notification } = useRecoilValue(IframePropsState);
  const isIframeOpen = useRecoilValue(IsOpenInIframeState);

  const successNotification = useCallback(
    (msg: string) => {
      return toast.success(msg);
    },
    [isIframeOpen, notification]
  );

  const infoNotification = useCallback(
    (msg: string) => {
      return toast.info(msg);
    },
    [isIframeOpen, notification]
  );

  const errorNotification = useCallback(
    (msg: string) => {
      return toast.error(msg);
    },
    [isIframeOpen, notification]
  );

  const warningNotification = useCallback(
    (msg: string) => {
      return toast.warning(msg);
    },
    [isIframeOpen, notification]
  );

  return {
    successNotification,
    infoNotification,
    errorNotification,
    warningNotification,
  };
};
