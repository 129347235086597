export const SHARE_TABLE_COLUMN = {
    HEADER: [
        {
            label: 'Share Class ',

            key: 'ShareClassName',
            format: 'jsx',
            className: '',
            width: '25%',
        },
        {
            label: 'Prefix',
            key: 'prefix',
            format: 'jsx',
            className: '',
            width: '10%',
        },
        {
            label: 'Class Type',
            key: 'classType',
            format: 'jsx',
            width: '15%',
        },
        {
            label: 'Certificated',
            key: 'certificate',
            format: 'jsx',
            width: '15%',
        },
        {
            label: 'Issue Priced (USD)',
            key: 'IssuePrice',
            format: 'jsx',
            width: '15%',
        },
        {
            label: 'Conversion Price(USD)',
            key: 'ConversionPrice',
            format: 'jsx',
            width: '25%',
        },
        {
            label: 'Divident',
            key: 'Divide',
            format: 'jsx',
            width: '50%',
        },
      
    ],
};

export const shareClassRowData = [
    {
        ShareClassName: 'Class name X',
        prefix: 'A',
        classType: 'Common',
        certificate: 'Yes',
        IssuePrice: '90',
        ConversionPrice: '120',
        Divide:'Cash',
        
    },
    {
        ShareClassName: 'Class name Y',
        prefix: 'B',
        classType: 'Preffered',
        certificate: 'No',
        IssuePrice: '35',
        ConversionPrice: '50',
        Divide:'',
        
    },
    

    
];
    
