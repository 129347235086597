import { Loader } from '@storybook';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import './custom-upload.scss';

interface ICustomUpload {
	type?: string;
	handleDropData?: any;
	icon?: string;
	text?: string;
}

export const CustomUpload: FC<ICustomUpload> = ({
	handleDropData,
	icon,
	text,
}) => {
	const [loading, setLoading] = useState<boolean>(false);

	const handleImportImage = useCallback(
		async (e: ChangeEvent<HTMLInputElement> | any) => {
			setLoading(true);
			const file = e.target.files;
			const FILES = [...file];
			handleDropData(FILES);
			if (file) {
				e.target.value = '';
			}
			setLoading(false);
		},
		[handleDropData]
	);

	return (
		<div className="CustomUpload--fields">
			<div className="browse-file">
				<label htmlFor="browse-file" className="browse-file__label-box">
					<>
						{loading ? (
							<div
								style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
							>
								<Loader className="loader-blue" type="loader" dimension={60} />
								<div style={{ fontSize: '16px' }}>Uploading file...</div>
							</div>
						) : (
							<>
								<i className={`${icon} browse-file__logo`} />
								<div className="browse-file__label-text">
									<span className="browse-file__light-text">
										{`${text} `}
										<span
											style={{
												color: 'var(--color-primary-light)',
												marginRight: '2px',
											}}
										>
											<a style={{ color: '#2269D3' }}>Browse file</a>
										</span>
									</span>
								</div>
							</>
						)}
					</>

					<input
						multiple={true}
						accept=".pdf, .docx , .png, .jpg, .jpeg"
						id="browse-file"
						type="file"
						onChange={handleImportImage}
						className="browse-file__input"
					/>
				</label>
			</div>
		</div>
	);
};
