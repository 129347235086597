import { atom } from "recoil";

export const IsOpenInIframeState = atom<boolean>({
    key: "is-iframe-state",
    default: window.self !== window.top,
  });
  
export const IframePropsState = atom<any>({
    key: "iframe-props-state",
    default: {
      background: "",
      theadColor: "",
      isSearch: false,
      clientId: "",
      clientSecret: "",
      btnPrimary: '',
      btnSecondary: '',
      notification: false,
      webAdminType: '',
      sidebar: false
    },
  });
  