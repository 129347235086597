import { Button, Loader } from '@storybook';
import { useNetwork, useNotification } from 'hooks';
import { FC, useCallback, useState, useEffect } from 'react';
import {
	CustomUpload,
	UplaodedFiles,
} from 'views/additionalinformation/component';
import { MAX_FILE_COUNT, MAX_FILE_SIZE } from '../constant';
import { API_URL } from 'constant';

interface IModal {
	visible: boolean;
	handleClose: () => void;
	headingLabel: string;
	currentFolder: string;
	parentFolder: string;
	rootFolder: string;
	getDataRoom: () => void;
	setShowUploadModal: (status: boolean) => void;
}

export const UploadModal: FC<IModal> = ({
	visible,
	handleClose,
	headingLabel,
	currentFolder,
	parentFolder,
	rootFolder,
	getDataRoom,
	setShowUploadModal,
}) => {
	const [basicInfoData, setBasicInfoData] = useState<any>({
		userUploadDocs: [],
	});
	const [newFile, setNewFile] = useState<string | ArrayBuffer | null | any>([]);
	const [isDisable, setIsDisable] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const { successNotification, errorNotification } = useNotification();
	const {
		post: addNewFile,	
		loading: addNewFileLoading,
	} = useNetwork();

	if (!visible) {
		return null;
	}

	const handleDropData = useCallback(
		async (item: any) => {
			setBasicInfoData((prevState: any) => {
				const newState: any = { ...prevState };
				const data = [...basicInfoData.userUploadDocs, ...item];
				newState['userUploadDocs'] = data;
				return newState;
			});

			const handleFileUpload = () => {
				const file = item;
				console.log('file::', file[0].size); //eslint-disable-line
				const base64Strings: any = [];

				Array.from(file).forEach((file) => {
					const reader = new FileReader();

					reader.onload = () => {
						base64Strings.push(reader.result);

						const modifiedBase64String = base64Strings.map((item: any) => {
							return item.split(',')[1];
						});
						const filePack = item.map((fileName: any, index: any) => {
							setNewFile([
								...newFile,
								{
									name: fileName?.name,
									base64url: modifiedBase64String[index],
								},
							]);
						});

						console.log(filePack); //eslint-disable-line
					};

					if (file instanceof Blob) {
						reader.readAsDataURL(file);
					} else {
						errorNotification('Invalid File');
					}
				});
			};
			handleFileUpload();
		},
		[
			basicInfoData.userUploadDocs,
			setBasicInfoData,
			newFile,
			basicInfoData,
			newFile.length,
		]
	);

	const calculateFileSize = () => {
		const sum: any = basicInfoData.userUploadDocs.reduce(
			(total: any, currentValue: any) => (total = total + currentValue.size),
			0
		);

		if (sum === 0) {
			setIsDisable(true);
		} else if (sum >= MAX_FILE_SIZE) {
			setIsDisable(true);
			errorNotification('file size limit exceeded');
		} else {
			setIsDisable(false);
		}
	};
	useEffect(() => {
		calculateFileSize();
	}, [basicInfoData.userUploadDocs]);
	
	const handleDeleteFile = useCallback(
		(selectedItem: any) => {
			const data = basicInfoData.userUploadDocs;
			const filteredItems = data.filter(function (item: any) {
				return item.name !== selectedItem.name;
			});
			setBasicInfoData((prevState: any) => {
				const newState: any = { ...prevState };
				newState['userUploadDocs'] = filteredItems;
				return newState;
			});
			successNotification('File deleted successfully');
		},
		[basicInfoData.userUploadDocs, setBasicInfoData, successNotification]
	);

	const handleUploadFile = useCallback(async () => {
		setIsLoading(true);
		const payload = {
			currentFolder: currentFolder,
			parentFolder:
				parentFolder[
					parentFolder.length >= 2
						? parentFolder.length - 2
						: parentFolder.length - 1
				],
			rootFolder: rootFolder,
			files: newFile,
		};
		if (newFile.length <= MAX_FILE_COUNT) {
			await addNewFile(`${API_URL.DATA_ROOM}`, payload);
			getDataRoom();
		} else {
			errorNotification(`You can not add more than ${MAX_FILE_COUNT}`);
		}
	}, [newFile, basicInfoData, isLoading]);

	useEffect(() => {
		if (isLoading && addNewFileLoading === false) {
			setIsLoading(false);
			setShowUploadModal(false);
		}
	}, [isLoading, addNewFileLoading]);

	const renderButtonText = useCallback(() => {
		return isLoading ? (
			<Loader className="loader-blue" type="loader" dimension={16} />
		) : (
			'Upload'
		);
	}, [isLoading]);

	return (
		<div className="modal-main">
			<div className="modal-main__content">
				<div className="modal-main__heading-row">
					<div className="modal-main__label">{headingLabel}</div>
					<div className="modal-main__delete-icon">
						<i
							className="ri-close-circle-line"
							onClick={() => handleClose()}
						></i>
					</div>
				</div>
				<div className="form-body">
					<div
						style={{
							height:
								basicInfoData?.userUploadDocs.length > 0 ? '30vh' : '50vh',
						}}
					>
						<CustomUpload
							handleDropData={handleDropData}
							icon={'ri-upload-cloud-line'}
							text={'Drag & drop files here or'}
						/>
					</div>
					{basicInfoData?.userUploadDocs.length > 0 && (
						<UplaodedFiles
							files={basicInfoData.userUploadDocs}
							handleDeleteFile={handleDeleteFile}
						/>
					)}
				</div>
				<div className="add-member-footer">
					<div className="add-member-footer__right">
						<Button
							label={'Cancel'}
							width="100px"
							handleClick={() => handleClose()}
							type={'sumbit'}
							fontColor="black"
							backgroundcolor="#f0f4ff"
						/>
						<Button
							label={renderButtonText()}
							width="100px"
							backgroundcolor="black"
							handleClick={() => handleUploadFile()}
							type={'sumbit'}
							disabled={isDisable}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
