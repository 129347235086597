import { Header } from "views/header";
import { RolePermissionBody } from "./components";
import "./role-permission.scss";

export const RoleAndPermission = () => {
  return (
    <div className="permission-wrapper">
      <Header title="Role and Permission" />
      <RolePermissionBody />
    </div>
  );
};
