import { useCallback, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';
import { DashboardPage } from 'views/dashboard-page';
import { RoleAndPermission } from 'views/role-and-permission';
import { Sidebar } from 'views/sidebar';
import './dashboard.scss';
import { routeState } from 'states';
import { useRecoilValue } from 'recoil';
import { DataRooms } from 'views/data-rooms';
import { Stakeholder } from 'views/sidebar/stakeholder/stakeholder';
import { ShareEmpty } from 'views/shares/components/share-empty/shareempty';
import { Equity } from 'views/equity-plan';

// routes
const {
	SETUP,
	DASHBOARD,
	DATA_ROOMS,
	SETTINGS,
	SHARES,
	SHARE_CLASS,
	FINANCIALS,
	TAXES,
	TEMPLATES,
	EQUITY_PLAN,
	STAKEHOLDER,
	OPTIONS,
	CONVERTiBLES,
} = ROUTES;

export const DashBoard = () => {
	const isrouteState = useRecoilValue(routeState);
	const navigate = useNavigate();

	const getCookie = (key: string) => {
		const name = key + '=';
		const decodedCookie = decodeURIComponent(document.cookie);
		const decodeCookie = decodedCookie.split(';');
		for (let i = 0; i < decodeCookie.length; i++) {
			let decodeKey: any = decodeCookie[i];
			while (decodeKey.charAt(0) === ' ') {
				decodeKey = decodeKey.substring(1);
			}
			if (decodeKey.indexOf(name) === 0) {
				return decodeKey.substring(name.length, decodeKey.length);
			}
		}
		return '';
	};

	const localStorageData = getCookie('user' ?? '');

	useEffect(() => {
		if (!localStorageData) {
			navigate(ROUTES.LOG_IN);
		}
	}, [localStorageData]);

	const routing = useCallback(() => {
		return (
			<Routes>
				{isrouteState && (
					<Route path={`${SETUP}`} element={<DashboardPage />} />
				)}
				<Route path={`${DASHBOARD}`} element={<DashboardPage />} />
				<Route path={`${DATA_ROOMS}`} element={<DataRooms />} />
				<Route path={`${SHARES}`} element={<ShareEmpty />} />
				<Route path={`${SHARE_CLASS}`} element={<DashboardPage />} />
				<Route path={`${SETTINGS}`} element={<DashboardPage />} />
				<Route path={`${FINANCIALS}`} element={<DashboardPage />} />
				<Route path={`${TAXES}`} element={<RoleAndPermission />} />
				<Route path={`${TEMPLATES}`} element={<DashboardPage />} />
				<Route path={`${EQUITY_PLAN}`} element={<Equity />} />
				<Route path={`${STAKEHOLDER}`} element={<Stakeholder />} />
				<Route path={`${OPTIONS}`} element={<DashboardPage />} />
				<Route path={`${CONVERTiBLES}`} element={<DashboardPage />} />
				<Route path="/*" element={<Navigate to={DASHBOARD} />} />
			</Routes>
		);
	}, []);

	return (
		<div>
			<div className="main-body">
				<Sidebar brandLoaded={false} />
				{routing()}
			</div>
		</div>
	);
};
