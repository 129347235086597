import { ReactResponsiveTable } from '@storybook/react-responsive-table';
import { STAKE_HOLDER_TABLE_COLUMN, stakeRowData } from './constant';
import { Header } from 'views/header';
import { useEffect, useMemo, useState } from 'react';
import { stakeholderState } from 'states';
import { useSetRecoilState } from 'recoil';

import './stakeholder.scss';
import { Skeleton } from 'skeleton-loader';

export const Stakeholder = () => {
	const [loading, setLoading] = useState(true);
	const [userListRow, setUserListRows] = useState([]);
	const setGetSimpliciData = useSetRecoilState(stakeholderState);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 3000);
	}, []);

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const token = queryParams.get('token');
		const themeColor = queryParams.get('themeColor');

		setGetSimpliciData({
			token: token || '',
			themeColor: themeColor || null,
		});
	}, []);

	useEffect(() => {
		if (STAKE_HOLDER_TABLE_COLUMN.HEADER.length) {
			const rows: any = [];
			stakeRowData?.forEach((item: any) => {
				if (item) {
					const row: any = {};

					STAKE_HOLDER_TABLE_COLUMN.HEADER?.forEach(() => {
						const renderStakeholder = () => {
							return (
								<div className="stakeholderRow">
									<span className="name">{item?.name}</span>
									<span className="greyColor">{item?.email}</span>
								</div>
							);
						};
						row.stakeholderName = renderStakeholder();
						const renderOutStanding = () => {
							return (
								<div className="stakeholderRow">
									<span className="name">{item?.outstanding}</span>
									<span className="greyColor">{item?.outstandingPercent}</span>
								</div>
							);
						};
						row.outstanding = renderOutStanding();
						const renderFullyDiluted = () => {
							return (
								<div className="stakeholderRow">
									<span className="name">{item?.fullyDiluted}</span>
									<span className="greyColor">{item?.fullyDilutedPercent}</span>
								</div>
							);
						};
						row.fullyDiluted = renderFullyDiluted();
						const renderTotalCash = () => {
							return (
								<div className="stakeholderRow">
									<span className="name">{item.totalCashRaised}</span>
									<span className="greyColor">{item?.totalCashRaised}</span>
								</div>
							);
						};
						row.totalCashRaised = renderTotalCash();
					});

					rows.push(row);
				}
			});
			return setUserListRows(rows);
		}
	}, [loading]);

	const renderHeader = useMemo(() => {
		return (
			<div className="header-container">
				<div className="box">
					<div className="icon"></div>
					<div className="shares-container">
						<span className="amount">
							{loading ? <Skeleton width={120} /> : '10,100,000'}
						</span>
						<span className="greyColor">
							{loading ? <Skeleton width={120} /> : 'Fully diluted shares'}
						</span>
					</div>
				</div>
				<div className="box">
					<div className="icon"></div>
					<div className="shares-container">
						<span className="amount">
							{loading ? <Skeleton width={120} /> : '10,100,000'}
						</span>
						<span className="greyColor">
							{loading ? <Skeleton width={120} /> : 'Amount raised (USD)'}
						</span>
					</div>
				</div>
				<div className="box">
					<div className="icon"></div>
					<div className="shares-container">
						<span className="amount">
							{loading ? <Skeleton width={120} /> : '10,100,000'}
						</span>
						<span className="greyColor">
							{loading ? <Skeleton width={120} /> : 'Available shares'}
						</span>
					</div>
				</div>
			</div>
		);
	}, [loading]);

	return (
		<div className="stake-container">
			<Header title="Stakeholder" />
			{renderHeader}
			<div className="stake-wrapper">
				<div className="stake">
					<ReactResponsiveTable
						isLoading={loading}
						isLoaded
						column={STAKE_HOLDER_TABLE_COLUMN.HEADER}
						rows={userListRow}
						className="stake-captable__table"
						showSearch={true}
						height="calc(100vh - 300px)"
						emptyHeight={'calc(100vh - 255px)'}
						columnHandle={true}
						headerbtnlable={'Draft Shares'}
					/>
				</div>
			</div>
		</div>
	);
};
