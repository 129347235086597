export const STAKE_HOLDER_TABLE_COLUMN = {
	HEADER: [
		{
			label: 'Stakeholder ',
			key: 'stakeholderName',
			format: 'jsx',
			className: '',
			width: '25%',
		},
		{
			label: 'Outstanding',
			key: 'outstanding',
			format: 'jsx',
			className: '',
			width: '25%',
		},
		{
			label: 'Fully diluted',
			key: 'fullyDiluted',
			format: 'jsx',
			width: '25%',
		},
		{
			label: 'Total cash raised (USD)',
			key: 'totalCashRaised',
			format: 'jsx',
			width: '25%',
		},
	],
};

export const stakeRowData = [
	{
		name: 'arun',
		outstanding: '5423530',
		outstandingPercent: '1.652%',
		fullyDiluted: '10320',
		fullyDilutedPercent: '10.5510%',
		totalCashRaised : '54410',
		email: "alina.trombley09@satschel.com"
	},
	{
		name: 'arun',
		outstanding: '354635',
		outstandingPercent: '3.651%',
		fullyDiluted: '25400',
		fullyDilutedPercent: '2.6500%',
		totalCashRaised : '65020',
		email: "alina.trombley01@satschel.com"
	},
	{
		name: 'arun',
		outstanding: '645670',
		outstandingPercent: '2.7546%',
		fullyDiluted: '51100',
		fullyDilutedPercent: '10.65140%',
		totalCashRaised : '51620',
		email: "alina.trombley00003@satschel.com"
	},
	{
		name: 'arun',
		outstanding: '1547654',
		outstandingPercent: '3.4576%',
		fullyDiluted: '65127',
		fullyDilutedPercent: '6.517%',
		totalCashRaised : '6521460',
		email: "alina.trombley1@satschel.com"
	},
	{
		name: 'arun',
		outstanding: '15475',
		outstandingPercent: '3.547%',
		fullyDiluted: '65147',
		fullyDilutedPercent: '6.5157%',
		totalCashRaised : '632130',
		email: "alina.trombley1@satschel.com"
	},
	{
		name: 'arun',
		outstanding: '51451',
		outstandingPercent: '3.515%',
		fullyDiluted: '665127',
		fullyDilutedPercent: '6.5157%',
		totalCashRaised : '156150',
		email: "alina.trombley1@satschel.com"
	},
	{
		name: 'arun',
		outstanding: '1151',
		outstandingPercent: '3.15656%',
		fullyDiluted: '6657',
		fullyDilutedPercent: '6.5127%',
		totalCashRaised : '56405',
		email: "alina.trombley1@satschel.com"
	},
	{
		name: 'arun',
		outstanding: '1652',
		outstandingPercent: '2.5113%',
		fullyDiluted: '65127',
		fullyDilutedPercent: '6.1257%',
		totalCashRaised : '1655260',
		email: "alina.trombley1@satschel.com"
	},
	{
		name: 'arun',
		outstanding: '165145',
		outstandingPercent: '3.512%',
		fullyDiluted: '515267',
		fullyDilutedPercent: '6.515217%',
		totalCashRaised : '26520',
		email: "alina.trombley1@satschel.com"
	},
	
	
];
	