import { FC, useCallback, useState, useEffect } from 'react';
import { Button, Input, Loader, ReactDropdown } from '@storybook';
import { SUB_ROLE_TYPE, USER_ROLE_TYPE, USER_TYPE } from '../constant';
import './add-member.scss';
import { useNetwork } from 'hooks';
import { useSetRecoilState } from 'recoil';
import { MemberListData } from 'states/memberstate';
import { getPermissionLable, validateEmailString } from 'hooks/web-authentication/utils';
import { API_URL } from 'constant';
interface IModal {
	visible: boolean;
	handleClose: () => void;
	headingLabel: string;
	getModalData: any;
}

interface IRolePermissions {
	boardMemberRole: string;
	startDate: string;
	endDate: string;
}
interface IAddMemberData {
	firstName: string;
	lastName: string;
	email: string;
	role: string[];
	status: string;
	rolePermissions: IRolePermissions;
}

export const AddBoardMember: FC<IModal> = ({
	visible,
	handleClose,
	headingLabel,
	getModalData
}) => {
	const SetMemberList = useSetRecoilState(MemberListData);
	// api state
	const { post: addMember, loading:addMemberLoading } = useNetwork();
	const { get: getMemberList } = useNetwork();
	const { patch: updateMemberData, loading:updateMemberLoading } = useNetwork();
	// local states
	const [addMemberData, setAddMemberData] = useState<IAddMemberData>({
		firstName: getModalData?.firstName ?? '',
		lastName: getModalData?.lastName ?? '',
		email: getModalData?.email ?? '',
		status:'Not yet registered',
		role: [headingLabel],
		rolePermissions: {
			boardMemberRole: getModalData?.rolePermissions?.boardMemberRole ?? '',
			startDate: getModalData?.rolePermissions?.startDate.split('T')[0] ?? '',
			endDate: getModalData?.rolePermissions?.endDate.split('T')[0] ?? '',
		},
	});
	const [error, setError] = useState(false);
	const [isloading, setIsLoading] = useState(false);

	const controlStyle = {
		minHeight: 42,
		minWidth: 180,
		height: 42,
		borderRadius: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.06)',
		'&:hover': { borderColor: 'black', cursor: 'pointer' },
		fontWeight: 500,
		fontSize: 14,
	};

	const optionStyle = {
		height: 42,
		minHeight: 42,
		'&:hover': { background: 'var(--color-black)', color: 'white' },
	};

	const getmembersdata = () => {
		getMemberList(`${API_URL.ROLES_PERMISSION}${headingLabel}`).then((data) =>
			SetMemberList(data?.data)
		);
	};

	if (!visible) {
		return null;
	}
	/* eslint-disable */
	const handleChangeData = useCallback(
		(type: string, e: React.ChangeEvent<HTMLInputElement>) => { 
			const { value } = e.target;
			setAddMemberData((prevState) => {
				const newState: any = { ...prevState };
				if (type === 'startDate' || type === 'endDate') {
					newState.rolePermissions = {
						...newState.rolePermissions,
						[type]: value,
					};
				} else {
					newState[type] = value;
				}
				return newState;
			});
			const isValidEmail = validateEmailString(addMemberData.email);
			if (type === 'email') {
				if (isValidEmail) {
					setError(false);
				} else {
					setError(true);
				}
			}
		},
		[addMemberData,error]
	);

	const handleDropChange = useCallback(
		(item: { label: string; value: string }) => {
			setAddMemberData((prevState) => ({
				...prevState,
				rolePermissions: {
					...prevState.rolePermissions,
					boardMemberRole: item.value,
				},
			}));
		},
		[setAddMemberData]
	);

	const handleAddClick = async () => {
		setIsLoading(true);
		const payload = {
			...addMemberData,
		};
		{!getModalData && await addMember(`${API_URL.USER_INVITE_ROLE}${headingLabel}`, payload).finally(() => {
			getmembersdata();
		})}

		{getModalData && await updateMemberData(`${API_URL.USERS}${getModalData._id}`, {
			firstName: addMemberData.firstName,
			lastName: addMemberData.lastName,
			role: [USER_TYPE.BOARD_MEMBERS],
			startDate: addMemberData.rolePermissions.startDate,
			endDate: addMemberData.rolePermissions.endDate,
			boardMemberRole: addMemberData.rolePermissions.boardMemberRole,
		}).finally(() => {
			getmembersdata();
		})}
	};

	useEffect(() => {
		if(isloading && addMemberLoading===false && updateMemberLoading===false) {
			setIsLoading(false);
			handleClose();
		}
	}, [updateMemberLoading,addMemberLoading,isloading])

	const isDisabled = () => {
		const isValidEmail = validateEmailString(addMemberData.email);
		const getModalStrtDate =
			getModalData?.rolePermissions?.startDate.split('T')[0] ?? '';
		const getModalEndDate =
			getModalData?.rolePermissions?.endDate.split('T')[0] ?? '';
		const isValidData =
			addMemberData.firstName &&
			addMemberData.lastName &&
			isValidEmail &&
			addMemberData.role &&
			addMemberData.rolePermissions.startDate &&
			addMemberData.rolePermissions.endDate &&
			addMemberData.rolePermissions.boardMemberRole;
		const isDataMatch =
			addMemberData.firstName !== getModalData?.firstName ||
			addMemberData.lastName !== getModalData?.lastName ||
			addMemberData?.email !== getModalData?.email ||
			addMemberData.rolePermissions.startDate !== getModalStrtDate ||
			addMemberData.rolePermissions.endDate !== getModalEndDate ||
			addMemberData.rolePermissions.boardMemberRole !==
				getModalData?.rolePermissions.boardMemberRole;

		if (getModalData ? isDataMatch && isValidData : isValidData) {
			return false;
		}
		return true;
	};

	const renderButtonLable = useCallback(() => {
		return (
			<>
				{isloading ? (
					<Loader className="loader-blue" type="loader" dimension={16} />
				) : getModalData ? (
					'Update'
				) : (
					'Invite'
				)}
			</>
		);
	},[isloading])

	return (
		<div className="modal-main">
			<div className="modal-main__content">
				<div className="modal-main__heading-row">
					<div className="modal-main__label">{getPermissionLable(headingLabel,USER_ROLE_TYPE)}</div>
					<div className="modal-main__delete-icon">
						<i
							className="ri-close-circle-line"
							onClick={() => handleClose()}
						></i>
					</div>
				</div>
				<div className="form-body">
					<div className="form-body__row">
						<div className="form-body__half-space">
							<Input
								handleChange={(event) => handleChangeData('firstName', event)}
								inputType={'text'}
								placeholder={'First Name'}
								label="First Name"
								value={addMemberData?.firstName}
								required
							/>
						</div>
						<div className="form-body__half-space">
							<Input
								inputType={'text'}
								placeholder={'Last Name'}
								label="Last Name"
								handleChange={(event) => handleChangeData('lastName', event)}
								value={addMemberData?.lastName}
								required
							/>
						</div>
					</div>
					<Input
						inputType={'text'}
						placeholder={'Email'}
						label="Email"
						handleChange={(event) => handleChangeData('email', event)}
						value={addMemberData?.email}
						isError={error}
						required
					/>
					<div className="form-body__row">
						<div className="form-body__half-space">
							Role
							<span style={{ marginLeft: '5px', color: '#DE7935' }}>
								(Required)
							</span>
							<div className="form-body__role-desp">
								Board member will be added to account users and will ave access
								to view the company’s cap table.
							</div>
						</div>
						<div className="form-body__half-space">
							<ReactDropdown
								controlStyle={controlStyle}
								optionsStyle={optionStyle}
								handleChangeSelect={handleDropChange}
								options={SUB_ROLE_TYPE}
								getOptionLabel={(option: any) => (
									<div>
										<div>{option.label}</div>
										<div style={{ fontSize: '12px', color: '#7A7A7A' }}>
											{option.description}
										</div>
									</div>
								)}
								value={{
									label: addMemberData.rolePermissions.boardMemberRole.length
										? addMemberData?.rolePermissions?.boardMemberRole
										: 'Select',
									value: addMemberData.rolePermissions.boardMemberRole,
								}}
								// defaultValue={SUB_ROLE_TYPE[0]}
							/>
						</div>
					</div>
					<div className="form-body__row">
						<div className="form-body__half-space date">
							<Input
								inputType={'date'}
								placeholder={'Start Date'}
								label="Start Date"
								handleChange={(event) => handleChangeData('startDate', event)}
								value={addMemberData?.rolePermissions?.startDate}
								required
							/>
						</div>
						<div className="form-body__half-space date">
							<Input
								inputType={'date'}
								placeholder={'End Name'}
								label="End Date"
								handleChange={(event) => handleChangeData('endDate', event)}
								value={addMemberData?.rolePermissions?.endDate}
								required
							/>
						</div>
					</div>
				</div>
				<div className="add-member-footer">
					<div className="add-member-footer__right">
						<Button
							label={'Cancel'}
							width="100px"
							handleClick={() => handleClose()}
							type={'sumbit'}
							fontColor="black"
							backgroundcolor="#f0f4ff"
						/>
						<Button
							label={renderButtonLable()}
							width="100px"
							backgroundcolor="black"
							handleClick={() => handleAddClick()}
							type={'sumbit'}
							disabled={isDisabled()}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
