import { FC } from "react";
import "./header.scss";

interface IHeader {
  heading: string;
  subHeading: string;
  width: string
}

export const AddtionalInfoHeader: FC<IHeader> = ({ heading, subHeading, width }) => {
  return (
    <div className="addtional-info-header">
      <div className="addtional-info-header__wrapper">
        <div className="addtional-info-header__heading">
{heading}
</div>
        <div className="addtional-info-header__sub-heading">
{subHeading}
</div>
      </div>
       <div className="border-box"></div>
      <div className="black-border-box" style={{width : width }}></div> 
    </div>
  );
};
