import { ReactResponsiveTable } from '@storybook/react-responsive-table';
import { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import {
	DATA_ROOMS_TYPE,
	DATA_ROOMS_COLOUMN,
} from '../constant';
import './data-rooms-body.scss';
import { UploadModal } from '../upload-modal';
import { useNetwork } from 'hooks';
import { getDate } from 'utils';
import { Button, Input, Loader } from '@storybook';
import { API_URL } from 'constant';
import { useRecoilValue } from 'recoil';
import { MemberListSearchText } from 'states/memberstate';

interface IrawItem {
	isFolder: boolean;
	id?: string;
	name: string;
	signedUrl?: string;
	items: number;
	lastmodified: string;
	action: string;
}

export const DataRoomsBody = () => {
	const [selectedUserTypeRole, setSelectedUserTypeRole] =
		useState<string>('files');
	const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
	const [isAddFolderModal, setIsAddFolderModal] = useState<boolean>(false);
	const [dataRoomsList, setDataRoomsList] = useState<IrawItem[]>();
	const [dataRoomsRawList, setDataRoomsRawList] = useState([]);
	const [dataRowList, setDataRowList] = useState([]);
	const [newFolderName, setNewFolderName] = useState('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const rootFolder = 'data room';
	const [currentFolder, setCurrentFolder] = useState(rootFolder);
	const [isDropOption, setIsDropOption] = useState<boolean>(false);
	const [parentFolderName, setParentFolderName] = useState<any>([rootFolder]);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const ref = useRef<HTMLDivElement>(null);
	const { get: getDataRoomsData } = useNetwork();
	const { post: addNewFolder } = useNetwork();
	const searchedText = useRecoilValue(MemberListSearchText);
	let getDataRoom: any; //eslint-disable-line

	const mapUserTypesRole = useMemo(() => {
		return DATA_ROOMS_TYPE.map((type) => (
			<div
				key={type.value}
				onClick={() => setSelectedUserTypeRole(type.value)}
				className={`role-box__user-role-left-user-types ${
					selectedUserTypeRole === type.value ? '--user-role-active' : ''
				}`}
			>
				{type.lebel}
			</div>
		));
	}, [selectedUserTypeRole]);

	const handleAddNewModal = useCallback(() => {
		setShowUploadModal(true);
	}, [showUploadModal]);

	const renderAddNewFolderModal = useCallback(() => {
		setIsAddFolderModal(true);
	}, [isAddFolderModal]);

	const handleCloseModal = useCallback(() => {
		setShowUploadModal(false);
		setIsAddFolderModal(false);
		setNewFolderName('');
	}, [isAddFolderModal, showUploadModal, newFolderName]);

	const refactorDataRoomData = (list: any, currentActiveFolder: string) => {
		const dataList: IrawItem[] = [];
		list?.map((item: any) => {
			if (item.files && item.currentFolder === currentActiveFolder) {
				item.files.map((file: any) => {
					const updatedDate =
						file?.updatedAt?.split('T')[0] ?? item?.updatedAt.split('T')[0];
					dataList.push({
						isFolder: false,
						name: file.name,
						signedUrl: file.signedUrl,
						items: 0,
						lastmodified: getDate(updatedDate),
						action: '',
					});
				});
			} else if (
				item?.parentFolder === currentActiveFolder ||
				(!item?.parentFolder && item?.rootFolder === currentActiveFolder)
			) {
				const updatedDate = item?.updatedAt.split('T')[0];
				dataList.push({
					isFolder: true,
					name: item?.currentFolder,
					items: item?.files?.length ?? 0,
					lastmodified: getDate(updatedDate),
					action: '',
					id: item._id,
				});
			}
		});
		setDataRoomsList(dataList);
	};

	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth);
		window.addEventListener("resize", handleResize, false);
	}, []);

	const createNewFolder = async (payload: any, apiCall=true) => {
		await addNewFolder(`${API_URL.DATA_ROOM}`, payload).finally(() => {
			setIsAddFolderModal(false);
			setNewFolderName('');
			setIsLoading(false);
		});
		if(apiCall) getDataRoom();
	};

	getDataRoom = () => {
		getDataRoomsData(`${API_URL.DATA_ROOM}`).then((data) => {
			if (data?.data?.length !== 0) {
				setDataRoomsRawList(data?.data[0]?.data);
				refactorDataRoomData(data?.data[0]?.data, currentFolder);
			}
		});
	};

	useEffect(() => {
		getDataRoom();
		setCurrentFolder(rootFolder);
		setParentFolderName([rootFolder]);
	}, []);

	const handleCurrentFolderName = (parentFolder: any) => {
		setCurrentFolder(parentFolder);
		setParentFolderName([...parentFolderName, parentFolder]);
		refactorDataRoomData(dataRoomsRawList, parentFolder);
		setIsDropOption(false);
	};
	const handleItemClick = (name: string, isFolder: any) => {
		if (isFolder) {
			handleCurrentFolderName(name);
		}
	}; 
	useEffect(() => {
		if (DATA_ROOMS_COLOUMN.length) {
			const rows: any = [];
			dataRoomsList?.forEach((item: any) => {
				if (item) {
					let row: any = {};
					DATA_ROOMS_COLOUMN.forEach(({ key }) => {
						const { name, items, lastmodified, action, isFolder } = item ?? {};
						const value = () => (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									cursor: 'pointer',
								}}
								
							>
								<i
									className={!isFolder ? 'ri-file-3-line' : 'ri-folder-2-fill'}
									style={{
										fontSize: '18px',
										paddingRight: '10px',
									}}
									onClick={() =>handleItemClick(name,isFolder)}
									
								/>
								{!isFolder ? (
									<span
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
									>
										{name}
									</span>
								) : (
									<span
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
										onClick={() => handleCurrentFolderName(name)}
									>
										{name}
									</span>
								)}
							</div>
						);

						const totalItems = () => {
							return (
								dataRoomsRawList.filter((data: any) =>
									data?.parentFolder===name
								).length + items
							);
						};
						row.searchFileName = name //use for searching and filter data by name
						row.name = value();
						row.items = totalItems() ?? '--';

						row.lastmodified = lastmodified ?? '--';
						const renderAction = () => (
							<i
								className="ri-more-2-line"
								style={{
									fontSize: '18px',
								}}
							/>
						);
						if (!action) {
							return (row.action = renderAction());
						}
						return (row = { ...row, [key]: item[key] });
					});
					rows.push(row);
				}
			});

			return setDataRowList(rows);
		}
	}, [dataRoomsList, searchedText, parentFolderName]);

	const handleCreateNewFolder = useCallback(async () => {
		setIsLoading(true);
		const payload = {
			currentFolder: newFolderName,
			parentFolder: currentFolder,
			rootFolder: rootFolder,
		};
		createNewFolder(payload,true);
	}, [newFolderName, currentFolder, rootFolder, isLoading]);

	const renderButtonLabel = useCallback(() => {
		return isLoading ? (
			<Loader className="loader-blue" dimension={16} type="loader" />
		) : (
			'Create'
		);
	}, [isLoading]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				setIsDropOption(false);
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [isDropOption]);

	const handleBreadCumbData = useCallback(
		(index: any) => {
			setParentFolderName(parentFolderName.slice(0, index + 1));
			setCurrentFolder(parentFolderName[index])
			refactorDataRoomData(dataRoomsRawList, parentFolderName[index]);
			setIsDropOption(false);
		},
		[parentFolderName, currentFolder, isDropOption]
	);

	const renderBreadCumbDropOption = useMemo(() => {
		const maxWidth = windowWidth<=778;
		return (
			<div className="breadCumb__dropOption-Wrapper">
				{[...parentFolderName].map((val, index) => {
					if (maxWidth ? (index >= 1) : (index >= 1 && index < parentFolderName.length - 2)) {
						return (
							<div className="breadCumb__dropOption-value" key={val}>
								<span onClick={() => handleBreadCumbData(index)}>{val}</span>
							</div>
						);
					} else {
						return null;
					}
				})}
			</div>
		);
	}, [parentFolderName, isDropOption, windowWidth]);

	const renderBreadCumb = useMemo(() => {
		const foldersLength = parentFolderName.length;
		const maxWidth = windowWidth<=778;
		return (
			<>
				<div className="breadCumb__wrapper">
					<div className="breadCumb__value">
						<span onClick={() => handleBreadCumbData(0)}>
							{parentFolderName[0]}
						</span>
						{foldersLength > 1 && (
							<i
								className="ri-arrow-right-s-line"
								style={{ fontSize: '24px' }}
							/>
						)}
					</div>
					{ (maxWidth ? foldersLength > 1 : foldersLength > 3) && (
						<>
							<div className="breadCumb__value" ref={ref}>
								<span onClick={() => setIsDropOption(!isDropOption)}>...</span>
								{!maxWidth && <i
									className="ri-arrow-right-s-line"
									style={{ fontSize: '24px' }}
								/>}
							</div>
							{isDropOption && renderBreadCumbDropOption}
						</>
					)}
					{foldersLength <= 3
						? [...parentFolderName].map((val, index) => {
								if (index >= 1 && index < 4 && !maxWidth) {
									return (
										<div className="breadCumb__value" key={val}>
											<span
												onClick={() => handleBreadCumbData(index)}
											>{`${val}`}</span>

											<i
												className={
													index !== foldersLength - 1
														? 'ri-arrow-right-s-line'
														: ''
												}
												style={{ fontSize: '24px' }}
											/>
										</div>
									);
								}
								return null;})
						: [...parentFolderName].map((val, index) => {
								if (index >= foldersLength - 2 && !maxWidth) {
									return (
										<div className="breadCumb__value" key={val}>
											<span
												onClick={() => handleBreadCumbData(index)}
											>{`${val}`}</span>
											<i
												className={
													index !== foldersLength - 1
														? 'ri-arrow-right-s-line'
														: ''
												}
												style={{ fontSize: '24px' }}
											/>
										</div>
									);
								}
								return null; })}
				</div>
			</>
		);
	}, [parentFolderName, isDropOption, windowWidth]);

	const renderDataRoomTable = useMemo(() => {
		return (
			<>
				<div className="breadCumb__container">{renderBreadCumb}</div>
				<ReactResponsiveTable
					column={DATA_ROOMS_COLOUMN}
					rows={dataRowList}
					height="calc(100vh - 300px)"
					emptyHeight={'calc(100vh - 255px)'}
					headerbtnlable="Add new folder"
					headerSecondBtnLable="Upload"
					ismultiheaderbtn={true}
					handleAddNewClick={renderAddNewFolderModal}
					handleSecondButtonClick={handleAddNewModal}
					isButton={true}
					showSearch={true}
				/>
			</>
		);
	}, [
		dataRowList,
		newFolderName,
		currentFolder,
		dataRoomsList,
		searchedText,
		parentFolderName,
		isDropOption,
		windowWidth
	]);

	return (
		<div className="data-room-wrapper">
			<div className="role-box">
				<div className="role-box__user-role-left">{mapUserTypesRole}</div>
				<div className="role-box__user-role-right">
					<div className="role-box__right-body-wrapper">
						{renderDataRoomTable}
					</div>
				</div>
			</div>
			{isAddFolderModal && (
				<div className="modal-main">
					<div className="modal-main__content" style={{ minWidth: '432px' }}>
						<div className="modal-main__heading-row">
							<div className="modal-main__label">New folder</div>
							<div className="modal-main__delete-icon">
								<i
									className="ri-close-circle-line"
									onClick={() => handleCloseModal()}
								></i>
							</div>
						</div>
						<div className="form-body">
							<Input
								label={'Folder Name'}
								inputType={'text'}
								placeholder={'Folder Name'}
								handleChange={(e) => setNewFolderName(e.target.value)}
								value={newFolderName}
							/>
						</div>
						<div className="add-member-footer">
							<div className="add-member-footer__right">
								<Button
									label={'Cancel'}
									width="100px"
									fontColor="black"
									backgroundcolor="#f0f4ff"
									handleClick={handleCloseModal}
								/>
								<Button
									label={renderButtonLabel()}
									width="100px"
									backgroundcolor="black"
									handleClick={handleCreateNewFolder}
									disabled = {!newFolderName.length}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
			{showUploadModal && (
				<UploadModal
					handleClose={() => handleCloseModal()}
					visible={showUploadModal}
					headingLabel={'Upload files'}
					currentFolder={currentFolder}
					parentFolder={parentFolderName}
					rootFolder={rootFolder}
					getDataRoom={getDataRoom}
					setShowUploadModal={setShowUploadModal}
				/>
			)}
		</div>
	);
};
