import { Button } from '@storybook/button';

import './table-header.scss';
import { ChangeEvent, useCallback, FC } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MemberListSearchText } from 'states/memberstate';
import { stakeholderState } from 'states';

interface ITableHeader {
	showSearchBy?: boolean;
	showFilterBtn?: boolean;
	handleAddNewClick?: () => void;
	handleSecondButtonClick?: () => void;
	ismultiheaderbtn?: boolean;
	headerbtnlable?: string | JSX.Element;
	headerSecondBtnLable?: string | JSX.Element;
}

export const TableHeader: FC<ITableHeader> = ({
	showSearchBy,
	// showFilterBtn,
	handleAddNewClick,
	ismultiheaderbtn,
	headerbtnlable,
	handleSecondButtonClick,
	headerSecondBtnLable
}) => {
	const setSearchedText = useSetRecoilState(MemberListSearchText);
	const bgColor = useRecoilValue(stakeholderState);
	const handleSearch = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setSearchedText(value);
		},
		[setSearchedText]
	);
	return (
		<div>
			<div className="table-header__user-role-header">
				<div></div>
				<div className="table-header__user-role-header-right-box">
					{showSearchBy && (
						<div className="table-header__user-role-header-right-input-box-div">
							<input
								className="table-header__user-role-header-right-input-box"
								type="search"
								placeholder="Search"
								onChange={handleSearch}
							/>
							<i className="ri-search-line" />
						</div>
					)}
					{/* it will use in future */}
					{/* {showFilterBtn && (
						<div className="table-header__user-role-header-right-icon-box">
							<i className="ri-filter-3-line"></i>
						</div>
					)} */}
					<Button
						handleClick={
							handleAddNewClick ? () => handleAddNewClick() : undefined
						}
						label={headerbtnlable || ''}
						backgroundcolor={bgColor.themeColor ?? 'black'}
					/>
					{ismultiheaderbtn && (
						<Button
							handleClick={
								handleSecondButtonClick
									? () => handleSecondButtonClick()
									: undefined
							}
							label={headerSecondBtnLable || ''}
							backgroundcolor="white"
							fontColor="black"
							border="solid 1px black"
						/>
					)}
				</div>
			</div>
		</div>
	);
};
