import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Button } from "@storybook";
import { ROUTES } from "routes";
import { useCookie, useNotification } from "hooks";
import { useCallback } from "react";
import { BANKS } from "./constant";
import { IsBasicInformationDone } from "states"; 
import { AddtionalInfoHeader } from "./component/header";
import { AddtionalInfoState } from "./store/states";
import "./additional-info.scss";

export const BankInformation = () => {
  const navigate = useNavigate();
  const { set: setCookie } = useCookie();

  const { successNotification } = useNotification();
  const SetAddtionInfoState = useSetRecoilState(AddtionalInfoState);
  const setIsBasicInformationDone = useSetRecoilState(IsBasicInformationDone);

  const renderBanks = useCallback(
    (item: { bankName: any; description: string }) => {
      return (
        <div className="bank-card-box">
          <div className="bank-card-box__icon">
            <i className="ri-bank-line"></i>
          </div>
          <div className="bank-card-box__name-wrapper">
            <div>
              <div className="bank-card-box__name">
{item.bankName ?? ""}
</div>
              <div className="bank-card-box__description">
                {item.description}
              </div>
            </div>
            <div>
              <Button
                handleClick={(e) => e}
                label="Connect"
                backgroundcolor="rgba(0, 0, 0, 0.06)"
                type={""}
                fontColor="black"
              />
            </div>
          </div>
        </div>
      );
    },
    []
  );

  const handleNextClick = useCallback(async () => {
    const cookieData = {
      accessToken: "",
      IsBasicInfoDone: true,
    };
    await setCookie("user", { ...cookieData });
    navigate(ROUTES.DASHBOARD);
    successNotification("Welcome to Carta");
    SetAddtionInfoState("basic-informations");
    setIsBasicInformationDone(true);
  }, [
    SetAddtionInfoState,
    navigate,
    setCookie,
    setIsBasicInformationDone,
    successNotification,
  ]);

  return (
    <div className="basic-infor-wrapper">
      <AddtionalInfoHeader
        width="66.66%"
        heading="Connect a bank account"
        subHeading="Suspendisse vitae tortor sed ipsum. Nibh purus ipsum lacus urna donec."
      />
      <div className="info-wrapper">
        {BANKS.map((item) => renderBanks(item))}
        <p className="info-wrapper__skip-btn">
SKIP
</p>
      </div>
      <div className="basic-infor-wrapper__footer">
        <Button
          handleClick={() => {
            SetAddtionInfoState("basic-informations");
          }}
          width="100px"
          backgroundcolor="rgba(0, 0, 0, 0.06)"
          label={"Back"}
          type={"sumbit"}
          fontColor="black"
        />
        <Button
          handleClick={() => handleNextClick()}
          width="100px"
          backgroundcolor="black"
          label={"Next"}
          type={"sumbit"}
        />
      </div>
    </div>
  );
};
