import { Button } from '@storybook';
import { useEffect, useMemo, useState } from 'react';
import {
	DASHBOARD_EQUITY_MANAGEMENT,
	DASHBOARD_SETUP_COMPANY,
} from '../constant';

import { ROUTES } from 'routes';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { selectedUserTypeRoleState } from 'states';
import './dashboard-body.scss';
import { useNetwork } from 'hooks';
import { API_URL } from 'constant';
import { Skeleton } from 'skeleton-loader';

export const DashboardBody = () => {
	const { get: getUserRole } = useNetwork();

	const [loading, setLoading] = useState(true);
	const [userRole, setUserRole] = useState();

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	}, []);

	useEffect(() => {
		if (!userRole) {
			getUserRole(API_URL.USER_ROLE).then((data) => {
				setUserRole(data);
			});
		}
	}, [getUserRole, userRole]);

	const setSelectedUserTypeRole = useSetRecoilState(selectedUserTypeRoleState);
	const navigate = useNavigate();

	const renderScreen = (item: any) => {
		setSelectedUserTypeRole(item.navigateKey);
		navigate(ROUTES.TAXES);
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		loading;
	};

	const renderSetupCompanyTable = useMemo(() => {
		return DASHBOARD_SETUP_COMPANY.INVITE_LIST.map((item: any) => (
			<>
				<div className="dashboard-body__setup-type-row">
					<div className="dashboard-body__setup-type-checked-circle">
						{item.complete && (
							<i className="ri-checkbox-circle-fill dashboard-body__setup-type-checked" />
						)}
					</div>
					<div className="dashboard-body__setup-type-name-wrapper">
						<div>
							<div className="dashboard-body__setup-type-name">
								{!loading ? item.label : <Skeleton height={10} />}
							</div>
							<div className="dashboard-body__setup-type-subname">
								{item.desp}
							</div>
						</div>
						<div>
							<Button
								label={item.btn_label}
								width={'100px'}
								fontColor={'black'}
								border={'2px solid #1f1f1f1f'}
								type={'text'}
								handleClick={() => renderScreen(item)}
							/>
						</div>
					</div>
				</div>
			</>
		));
	}, [loading]);

	const renderEquityManagementTable = useMemo(() => {
		return DASHBOARD_EQUITY_MANAGEMENT.INVITE_LIST.map((item: any) => (
			<>
				<div className="dashboard-body__setup-type-row">
					<div className="dashboard-body__setup-type-checked-circle">
						{item.complete && (
							<i className="ri-checkbox-circle-fill dashboard-body__setup-type-checked" />
						)}
					</div>
					<div className="dashboard-body__setup-type-name-wrapper">
						<div>
							<div className="dashboard-body__setup-type-name">
								{!loading ? item.label : <Skeleton height={10} />}
							</div>
							<div className="dashboard-body__setup-type-subname">
								{item.desp}
							</div>
						</div>
						<div>
							<Button
								label={item.btn_label}
								width="100px"
								border="2px solid #1f1f1f1f"
								fontColor="black"
								type={'text'}
							/>
						</div>
					</div>
				</div>
			</>
		));
	}, [loading]);

	return (
		<div className="dashboard-body-wrapper">
			<div className="dashboard-body">
				<div className="dashboard-body__setup-heading">
					<div className="dashboard-body__setup-heading-icon">
						<i className={DASHBOARD_SETUP_COMPANY.ICON}></i>
					</div>
					{DASHBOARD_SETUP_COMPANY.HEADING}
				</div>
				{renderSetupCompanyTable}
			</div>
			<div className="dashboard-body" style={{ marginTop: '30px' }}>
				<div className="dashboard-body__setup-heading">
					<div className="dashboard-body__setup-heading-icon">
						<i className={DASHBOARD_EQUITY_MANAGEMENT.ICON}></i>
					</div>
					{DASHBOARD_EQUITY_MANAGEMENT.HEADING}
				</div>
				{renderEquityManagementTable}
			</div>
		</div>
	);
};
