import { useEffect, useRef } from 'react';

export const INVESTOR_CAPTABLE_DATA = [
	[
		'Share class',
		'Prefix',
		'Class type',
		'Certificate',
		'Par value',
		'Seniority',
		'Pari passu',
		'Currency',
		'Original issue price',
		'Conversion price',
		'Multiplier',
		'Participating',
		'Participation cap',
		'Dividend',
		'Dividend yield',
		'Cumulative',
		'Dividend accrual',
		'Compounding interest',
	],
	[
		'',
		'',
		'Select class type',
		'Select',
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'',
		'Select',
		'',
		'Select',
		'',
		'Select',
		'Select',
		'Select',
	],
	[
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'',
		'Select',
		'',
		'Select',
		'',
		'Select',
		'Select',
		'Select',
	],
	[
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'',
		'Select',
		'',
		'Select',
		'',
		'Select',
		'Select',
		'Select',
	],
	[
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'',
		'Select',
		'',
		'Select',
		'',
		'Select',
		'Select',
		'Select',
	],
	[
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'',
		'Select',
		'',
		'Select',
		'',
		'Select',
		'Select',
		'Select',
	],
	[
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'',
		'Select',
		'',
		'Select',
		'',
		'Select',
		'Select',
		'Select',
	],
	[
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'',
		'Select',
		'',
		'Select',
		'',
		'Select',
		'Select',
		'Select',
	],
	[
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'',
		'Select',
		'',
		'Select',
		'',
		'Select',
		'Select',
		'Select',
	],
	[
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'',
		'Select',
		'',
		'Select',
		'',
		'Select',
		'Select',
		'Select',
	],
	[
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'Select',
		'Select',
		'',
		'',
		'',
		'Select',
		'',
		'Select',
		'',
		'Select',
		'Select',
		'Select',
	],
];

export const CaptableGrid = () => {
	const tableArray: any = [];
	const dataVerificationObj = {};
	const dataObj = {};
	const getDropDownValue: any = {
		'2': 'Common,Preferred',
		'3': 'Yes,No',
		'6': 'Yes,No',
		'7': 'USD,INR',
		'11': 'Yes,No',
		'13': 'Cash,No-cash',
		'15': 'Yes,No',
		'16': 'Yes,No',
		'17': 'Yes,No',
	};
	const getType = (row: number, column: number) => {
		const key = `${row}_${column}`;

		const payload = {
			[key]: {
				type: 'dropdown',
				type2: 'dropdown',
				// value1: 'Common,Preferred',
				value1: getDropDownValue[column as any],
				checked: false,
				remote: false,
				prohibitInput: false,
				hintShow: false,
				hintText: 'wwww',
			},
		};

		Object.assign(dataVerificationObj, dataObj, payload);
	};
	INVESTOR_CAPTABLE_DATA.forEach((rows: any, rowIndex: any) => {
		(rows ?? []).forEach((row: any, colIndex: any) => {
			if (
				colIndex === 2 ||
				colIndex === 3 ||
				colIndex === 6 ||
				colIndex === 7 ||
				colIndex === 11 ||
				colIndex === 13 ||
				colIndex === 15 ||
				colIndex === 16 ||
				colIndex === 17
			) {
				getType(rowIndex, colIndex);
			}
			const cellObj = {
				r: rowIndex,
				c: colIndex,
				v: {
					ct: { fa: 'General', t: 'g' },
					m: row,
					v: row,
				},
			};

			tableArray.push(cellObj);
		});
	});
	const luckysheet = useRef(null);

	useEffect(() => {
		luckysheet.current = (window as any)?.luckysheet;
		(luckysheet as any).current.create({
			container: 'luckysheet',
			showtoolbar: false,
			showsheetbar: false,
			showinfobar: false,
			showstatisticBar: false,
			sheetFormulaBar: false,
			defaultColWidth: 144,
			defaultRowHeight: 32,
			row: 15,
			data: [
				{
					celldata: tableArray,
					dataVerification: dataVerificationObj,
					dataObj: dataObj,
				},
			],
			hook: {
				cellUpdated: () => {
					return true;
				},
			},
		});
		setTimeout(() => {
			(luckysheet?.current as any)?.setHorizontalFrozen(false);
		}, 500);
		// eslint-disable-next-line
	}, []);

	return (
		<div
			id="luckysheet"
			style={{
				backgroundColor: '#F5F5F5',
				margin: '0px',
				padding: '0px',
				position: 'absolute',
				width: '100%',
				height: '100%',
				left: '0px',
				top: '30px',
				border: '1px solid  red',
				borderRadius: '4px',
			}}
		/>
	);
};
