import { Button, Input, ReactDropdown } from '@storybook';
import { useCallback, useState } from 'react';
import Modal from '@storybook/modal';
import { Equityday } from '../equityDay';
import {
	CustomUpload,
	UplaodedFiles,
} from 'views/additionalinformation/component';
import { useNotification } from 'hooks';
import './equitydetail.scss';

interface UploadDoc {
	userUploadDocs: any[];
}

export const Equitydetail = () => {
	const [close, setClose] = useState(false);
	const [open, setOpen] = useState(false);
	const [isequity, setIsEquity] = useState(true);
	const [basicInfoData, setBasicInfoData] = useState<UploadDoc>({
		userUploadDocs: [],
	});
	const { successNotification } = useNotification();

	const [addMemberData, setAddMemberData] = useState<any>({
		startDate: '',
		endDate: '',
	});
	/* eslint-disable */
	const handleChangeData = useCallback(
		(type: string, e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setAddMemberData((prevState: any) => {
				const newState: any = { ...prevState };
				newState[type] = value;
				return newState;
			});
		},
		[addMemberData]
	);

	const handleClose = () => {
		setClose(!close);
		setOpen(true);
	};
	const handleNextClick = () => {
		setIsEquity(false);
	};
	const handleDropData = useCallback(
		(item: any) => {
			setBasicInfoData((prevState: any) => {
				const newState: any = { ...prevState };
				const data = [...basicInfoData.userUploadDocs, ...item];
				newState['userUploadDocs'] = data;
				return newState;
			});
		},
		[basicInfoData.userUploadDocs, setBasicInfoData]
	);

	const handleDeleteFile = useCallback(
		(selectedItem: any) => {
			const data = basicInfoData.userUploadDocs;
			const filteredItems = data.filter(function (item: any) {
				return item.name !== selectedItem.name;
			});
			setBasicInfoData((prevState) => {
				const newState: any = { ...prevState };
				newState['userUploadDocs'] = filteredItems;
				return newState;
			});
			successNotification('File deleted successfully');
		},
		[basicInfoData.userUploadDocs, setBasicInfoData, successNotification]
	);

	return (
		<>
			<Modal
				modalName="Equity Plan"
				title="Equity plan"
				closeModal={handleClose}
				className="on-boarding-modal"
				isOpen={open}
			>
				{' '}
				<Equityday />
			</Modal>
			<div className="equity-plan">
				<div className="equidity-container">
					<div className="equidity-details">
						<div className="equity-body">
							{isequity ? (
								<div className="equity-body__inner">
									<div className="equity-body-form">
										<div className="input">
											<label className="input__label-first">
												Equity plan name
												<span className="required">(Required)</span>
											</label>
											<div className="input__value-container">
												<div className="input__group">
													<div className="input__text-field">
														<input
															placeholder="Plan Name"
															type="text"
															className="input__field"
															value=""
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="company-details-input-wrapper">
											<div className="company-details-outer">
												<div className="input-right">
													<label className="input__label-share">
														Share class
														<span className="required">(Required)</span>
													</label>
													<div className="input__group__dropdown">
														<div className="input__select-field">
															<div style={{ width: '112%' }}>
																{' '}
																<ReactDropdown options={[]} />
															</div>
														</div>
													</div>
												</div>
												<div className="input-left">
													<label className="input__label-total">
														Total reserved share
														<span className="required">(Required)</span>
													</label>
													<div className="input__group__dropdown">
														<div className="input__select-field">
															<div className="input__value-container">
																<div className="input__group-total">
																	<div className="input__text-field">
																		<input
																			placeholder=""
																			type="text"
																			className="input__field"
																			value=""
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="drag-drop">
											<div className="input__dropbox">
												<label className="input__label-heading">
													Equity incentive plan
													<span className="required">(Required)</span>
												</label>
											</div>

											<div className="drag-drop-box-outer">
												<div>
													<CustomUpload
														handleDropData={handleDropData}
														icon={'ri-upload-cloud-2-line'}
														text="Drag & drop files here or"
													/>
												</div>
												{basicInfoData?.userUploadDocs.length > 0 && (
													<UplaodedFiles
														files={basicInfoData.userUploadDocs}
														handleDeleteFile={handleDeleteFile}
														icon={''}
													/>
												)}
											</div>
										</div>

										<div className="form-body__row">
											<div className="form-body__half-space date">
												<Input
													inputType={'date'}
													placeholder={'Start Date'}
													label="Board approval date"
													handleChange={(event) =>
														handleChangeData('startDate', event)
													}
													required
												/>
											</div>
											<div className="form-body__half-space date">
												<Input
													inputType={'date'}
													placeholder={'End Name'}
													label="Stock holder approval date"
													handleChange={(event) =>
														handleChangeData('endDate', event)
													}
													required
												/>
											</div>
										</div>
										<div className="board-box">
											<div className="input-border">
												<label className="input__label-board">
													Board consent
													<span className="required">(Required)</span>
												</label>
												<div className="input__value-container">
													<div className="input__group">
														<div className="input__text-field-board">
															<input
																placeholder=""
																type="text"
																className="input__field"
																value=""
															/>
															<div className="button-box">
																<button className="btn">Browse</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="board-box">
											<div className="input-consent">
												<label className="input__label-consent">
													Stockholder consent
													<span className="required">(Required)</span>
												</label>
												<div className="input__value-container">
													<div className="input__group">
														<div className="input__text-field-board">
															<input
																type="text"
																className="input__field"
																value=""
															/>
															<div className="button-box">
																<button className="btn">Browse</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="footer-wraper">
										<div className="footer">
											<Button
												width="80px"
												backgroundcolor="black"
												borderRadius="4px"
												label="Next"
												fontSize="16px"
												handleClick={() => handleNextClick()}
												type={'sumbit'}
											/>
											<Button
												width="80px"
												borderRadius="4px"
												backgroundcolor="white"
												fontColor="black"
												border="1px solid #a3b1d6"
												fontSize="16px"
												label="Cancel"
												type={'sumbit'}
											/>
										</div>
									</div>
								</div>
							) : (
								<Equityday />
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
