import { useCallback } from "react";
import { REACT_APP_API_HOST as API_HOST } from "envs";

import { API_URL } from "constant/api";

export const useCountryStateCity = () => {
  let baseUrl = "";
  if (API_HOST?.indexOf("stage") !== -1)
    baseUrl = "https://api.stage.satschel.com/v1";
  else if (API_HOST.indexOf("pp") !== -1) baseUrl = "https://api.pp.satschel.com/v1";
  else baseUrl = "https://api.satschel.com/v1";

  const getStates = useCallback(
    async (country?: string) => {
      try {
        const resp = await fetch(
          `${baseUrl}${API_URL.STATE_CITY}?country=${
            country ?? "United States"
          }&type=state`
        );
        const apiResp = await resp.json();
        return apiResp.data;
      } catch (error) {
        return null;
      }
    },
    [baseUrl]
  );

  const getCities = useCallback(
    async (stateId: string) => {
      const resp = await fetch(
        `${baseUrl}${API_URL.STATE_CITY}?stateId=${stateId}&type=city`
      );
      const apiResp = await resp.json();
      return apiResp.data;
    },
    [baseUrl]
  );
  return { getStates, getCities };
};
