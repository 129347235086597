import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { Button, Input, Loader, ReactDropdown } from "@storybook";
import { useNetwork, useNotification } from "hooks";
import { API_URL } from "constant";
import {
  COMPANY_DETAILS,
  COMPANY_ADDRESS,
  controlStyle,
  optionStyle,
  BASIC_INFO,
  isRequired,
} from "./constant";
import {
  AddtionalInfoState,
  BasicInfoDataState,
  IInfoKey,
  IKeys,
  ISelectKeys,
  useCountryStateCity,
} from "./store";
import { CustomUpload, AddtionalInfoHeader, UplaodedFiles } from "./component";

import "./additional-info.scss";
import { MemberData } from "states/memberstate";
import { iFrameState } from "states";

interface StateItem {
  label: string;
  value: string;
  _id: string;
}

export const BasicInformationScreen = () => {
  // globel states
  const SetAddtionInfoState = useSetRecoilState(AddtionalInfoState);
  const setMemberData =  useSetRecoilState(MemberData);
  const [basicInfoData, setBasicInfoData] = useRecoilState(BasicInfoDataState);
  const setIframeState = useSetRecoilState(iFrameState);
	
  // local state
	const [infoHeader, setInfoHeader] = useState({
		companyAddress: false,
		companyDetails: true,
	});
	const [StateFormationList, setStateFormationList] = useState([]);
  const [stateList, setStateList] = useState<StateItem[]>([]);
	const [cityList, setCityList] = useState([]);

	//hook
	const { formData, loading } = useNetwork();
	const { errorNotification, successNotification } = useNotification();
	const { getStates, getCities } = useCountryStateCity();

	const stateId = useMemo(
		() =>
			Array.isArray(stateList) &&
			stateList.find(
				(item: { label: string; _id: string }) =>
					item.label === basicInfoData?.state
			)?._id,
		[basicInfoData?.state, stateList]
	);

	useEffect(() => {
		const isIframe = window.self !== window.top;
		if (isIframe) {
			setIframeState(true);
		} else {
			setIframeState(false);
		}
	}, []);

  useEffect(() => {
    if (basicInfoData?.country) {
      setBasicInfoData((pre) => ({
        ...pre,
        state: "",
        city: "",
      }));
      getStates(basicInfoData.country).then((states) => {
        if (states.length === 0) {
          setStateList([
            {
              label: basicInfoData?.country,
              value: basicInfoData?.country,
              _id: " ",
            },
          ]);
        } else
          setStateList(
            (states ?? []).map((state: { name: string; _id: string }) => ({
              label: state.name,
              value: state.name,
              _id: state._id,
            }))
          );
      });
    }
  }, [basicInfoData.country]);

  useEffect(() => {
    if (stateId && basicInfoData?.state) {
      setBasicInfoData((pre) => ({
        ...pre,
        city: "",
      }));
      getCities(stateId).then((cities) => {
        if (!cities || cities.length === 0) {
          setCityList([
            { label: basicInfoData.state, value: basicInfoData.state } as never,
          ]);
        } else
          setCityList(
            (cities ?? []).map((city: { name: string }) => {
              return {
                label: city.name,
                value: city.name,
              };
            })
          );
      });
    }
  }, [basicInfoData.state]);

  useEffect(() => {
    if (StateFormationList.length === 0) {
      getStates().then((res) => {
        setStateFormationList(
          (res ?? []).map((state: { name: string; _id: string }) => ({
            label: state.name,
            value: state.name,
          }))
        );
      });
    }
  }, [StateFormationList]);

  const handleFormData = useCallback(
    (type: string, e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (type === "zip" && (value.length > 6 || /[^0-9]/g.test(value))) return;
      setBasicInfoData((prevState) => {
        const newState: any = { ...prevState };
        newState[type] = value;
        return newState;
      });
    },
    [setBasicInfoData]
  );

  const handleDropChange = useCallback(
    (item: { label: string; value: string }, key: string) => {
      setBasicInfoData((prevState) => {
        const newState: any = { ...prevState };
        newState[key] = item?.value;
        return newState;
      });
    },
    [setBasicInfoData]
  );

  const handleDropData = useCallback(
    (item: any) => {
      setBasicInfoData((prevState) => {
        const newState: any = { ...prevState };
        const data = [...basicInfoData.companyFormationDocs, ...item];
        newState["companyFormationDocs"] = data;
        return newState;
      });
    },
    [basicInfoData.companyFormationDocs, setBasicInfoData]
  );

  const handleSumbit = useCallback(async () => {
    const emptyKeys = Object.keys(basicInfoData)
      .filter(
        (key) =>
          isRequired[key as "companyName"] &&
          basicInfoData[key as IKeys].trim()?.length === 0
      )
      .map((key) => isRequired[key as "companyName"]);
    if (emptyKeys.length) {
      errorNotification(emptyKeys + " can not be empty");
      return;
    }

    const payload = new FormData();

    payload.append("companyName", basicInfoData.companyName);
    payload.append("dba", basicInfoData.dba);
    payload.append("einNumber", basicInfoData.einNumber);
    payload.append("companyType", basicInfoData.companyType);
    payload.append("dateOfFormation", basicInfoData.dateOfFormation);
    payload.append("stateOfFormation", basicInfoData.stateOfFormation);
    payload.append("tcc", basicInfoData.tcc);
    payload.append("industrySector", basicInfoData.industrySector);
    payload.append("street", basicInfoData.street);
    payload.append("country", basicInfoData.country);
    payload.append("state", basicInfoData.state);
    payload.append("city", basicInfoData.city);
    payload.append("zip", basicInfoData.zip);

    basicInfoData.companyFormationDocs.map((item) => {
      return payload.append("docs", item);
    });
    SetAddtionInfoState("bank-information");
    const resp = await formData(API_URL.ADD_BASIC_INFORMATION, payload);
    if (resp?.data) {
      SetAddtionInfoState("bank-information");
      setMemberData(resp)
    } else {
      errorNotification(resp?.message);
    }
  }, [SetAddtionInfoState, basicInfoData, errorNotification, formData]);

  const handleDeleteFile = useCallback(
    (selectedItem: any) => {
      const data = basicInfoData.companyFormationDocs;
      const filteredItems = data.filter(function (item: any) {
        return item.name !== selectedItem.name;
      });
      setBasicInfoData((prevState) => {
        const newState: any = { ...prevState };
        newState["companyFormationDocs"] = filteredItems;
        return newState;
      });
      successNotification("file deleted successfully");
    },
    [basicInfoData.companyFormationDocs, setBasicInfoData, successNotification]
  );

  const nextBtnLabel = useMemo(() => {
    if (loading) {
      return <Loader className="loader-white" type="loader" dimension={14} />;
    }
    return "Next";
  }, [loading]);

  const renderCompanyDetailInput = useCallback(
    (key: IInfoKey) =>
      (key === "companyDetails" ? COMPANY_DETAILS : COMPANY_ADDRESS).map(
        ({ key, label, type, required, values }) => {
          if (key === "stateOfFormation") {
            values = StateFormationList;
          }
          if (key === "state") values = stateList;
          if (key === "city") values = cityList;
          switch (type) {
            case "dropdown":
              return (
                <div className="company-details-input-wrapper">
                  <ReactDropdown
                    label={label}
                    controlStyle={controlStyle}
                    optionsStyle={optionStyle}
                    handleChangeSelect={(e) => handleDropChange(e, key)}
                    options={values as any}
                    value={
                      basicInfoData[key as ISelectKeys] !== ""
                        ? {
                            label: basicInfoData?.[key as ISelectKeys],
                            value: basicInfoData?.[key as ISelectKeys],
                          }
                        : { label: "Select...", value: null }
                    }
                    defaultValue={values?.[0]}
                    required={required}
                  />
                </div>
              );
            case "file":
              return (
                <div className="company-details-input-wrapper company-details-upload-wrapper">
                  <div className="input__label input__label__custom">
                    {label}
                  </div>
                  <CustomUpload handleDropData={handleDropData} text={"Drag & drop files here or"}/>
                </div>
              );
            default:
              return (
                <div
                  className={`company-details-input-wrapper  ${
                    key === "street" ? "company-details-street-wrapper" : ""
                  }`}
                >
                  <Input
                    key={key}
                    label={label}
                    inputType={type as any}
                    placeholder={label}
                    handleChange={(e) => handleFormData(key, e)}
                    required={required}
                    value={basicInfoData[key as IKeys]}
                  />
                </div>
              );
          }
        }
      ),
    [
      StateFormationList,
      basicInfoData,
      cityList,
      handleDropChange,
      handleDropData,
      handleFormData,
      stateList,
    ]
  );

  const handleClickOnHeader = useCallback(
    (key: "companyDetails" | "companyAddress") => {
      setInfoHeader((prev) => ({
        companyDetails: false,
        companyAddress: false,
        [key]: !prev[key],
      }));
    },
    []
  );

  const renderHeaders = useCallback(
    (
      wrapper: string,
      label: string,
      desc: string,
      icon: string,
      key: "companyDetails" | "companyAddress"
    ) => (
      <div
        onClick={() => handleClickOnHeader(key)}
        className={`${wrapper}__header`}
      >
        <div className={`${wrapper}__header-left`}>
          <div className={`${wrapper}__header__icon`}>
            <i className={icon} />
          </div>
          <div className={`${wrapper}__header__title`}>{label}</div>
          <div className={`${wrapper}__header__desc`}>{desc}</div>
        </div>
        <div className={`${wrapper}__header-right`}>
          <i className={`ri-arrow-${infoHeader[key] ? "up" : "down"}-s-line`} />
        </div>
      </div>
    ),
    [handleClickOnHeader, infoHeader]
  );

  const renderBasicInfo = useMemo(
    () =>
      BASIC_INFO.map(({ key, icon, label, wrapper, desc }) => (
        <div key={key} className={wrapper}>
          {renderHeaders(wrapper, label, desc, icon, key as IInfoKey)}
          {infoHeader[key as IInfoKey] && (
            <div className={`${wrapper}__body`}>
              {renderCompanyDetailInput(key as IInfoKey)}
              {key === "companyDetails" &&
                basicInfoData?.companyFormationDocs.length > 0 && (
                  <UplaodedFiles
                    files={basicInfoData.companyFormationDocs}
                    handleDeleteFile={handleDeleteFile}
                  />
                )}
            </div>
          )}
        </div>
      )),
    [
      basicInfoData.companyFormationDocs,
      handleDeleteFile,
      infoHeader,
      renderCompanyDetailInput,
      renderHeaders,
    ]
  );

  return (
    <div className="basic-infor-wrapper">
      <AddtionalInfoHeader
        width="33.33%"
        heading="Company profile"
        subHeading="Suspendisse vitae tortor sed ipsum. Nibh purus ipsum lacus urna donec."
      />
      <div className="info-wrapper-container">
        <div className="info-wrapper">{renderBasicInfo}</div>
      </div>
      <div className="basic-infor-wrapper__footer">
        <Button
          width="100px"
          backgroundcolor="black"
          label={nextBtnLabel}
          handleClick={handleSumbit}
          type={"sumbit"}
          disabled={loading}
        />
      </div>
    </div>
  );
};
