import React from "react";
import "./tag-input.scss";

interface TagInputProps {
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

const TagInput: React.FC<TagInputProps> = ({
  tags,
  setTags,
  inputValue,
  setInputValue,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const addTags = () => {
    const newTags = inputValue
      .split(",")
      .map((tag) => tag.trim())
      .filter((tag) => tag !== "");

    const duplicateTags = newTags.filter((tag) => tags.includes(tag));
    if (duplicateTags.length > 0) {
    //   alert(`Tags '${duplicateTags.join(", ")}' already exist.`);
      return;
    }

    if (newTags.length > 0) {
      setTags((prevTags) => [...prevTags, ...newTags]);
      setInputValue("");
    }
  };

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      addTags();
    }
  };


  const removeTag = (index: number) => {
    setTags((prevTags) => prevTags.filter((_, i) => i !== index));
  };

  return (
    <div className="tags-wraaper">
      <div className="tags-container">
        {tags.map((tag, index) => ( // eslint-disable-next-line
          <div className="tag" key={index}>
            <span>
                {tag}
            </span>
            <button
              className="remove-tag-btn"
              onClick={() => removeTag(index)}
            >
              x
            </button>
          </div>
        ))}

        <input
          type="text"
          placeholder="Add Title"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
        />
      </div>
      <div className="add-btn-container">
        <span className="add-btn" onClick={addTags}>
          Add
        </span>
      </div>
    </div>
  );
};

export default TagInput;
