import { CaptableGrid } from '@storybook/captable-grid';

import './sharesheet.scss';

export const ShareSheet = () => {
	return (
		<>
			<div className="share-sheet-wrapper">
				<div>
					<CaptableGrid />
				</div>
			</div>
		</>
	);
};
