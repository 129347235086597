import type { IReactResponsiveTable } from './type';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getDate, getDateWithTime, getTime, useFormatNumber } from 'utils';
import { TableEmptyMessage } from './components';
import { TableHeader } from './table-header';
import { useRecoilValue } from 'recoil';
import { MemberListSearchText } from 'states/memberstate';
import { Skeleton } from 'skeleton-loader';
import './table-header.scss';
import './react-responsive-table.scss';
import { Loader } from '@storybook/loader';
import EmptyTable from 'assets/empty-table.svg';

export const ReactResponsiveTable: FC<IReactResponsiveTable> = ({
	tableType,
	column,
	rows = [],
	isLoading = false,
	className = '',
	columnHandle = false,
	handleAddNewClick,
	ismultiheaderbtn,
	headerbtnlable,
	headerSecondBtnLable,
	handleSecondButtonClick,
	isButton,
	showSearch = true,
	emptyIllustration = EmptyTable,
	emptyDescription = '',
}) => {
	const searchedText = useRecoilValue(MemberListSearchText);
	const [tableRowsData, setTableRows] = useState<any[]>(rows);
	const selectedDate: any = {};

	useEffect(() => {
		setTableRows(rows);
	}, [rows]);
	const [sortOrder, setSortOrder] = useState({
		column: '',
		order: 'asc',
	});
	const shortingColumn = [
		'Name',
		'Start Date',
		'End Date',
		'Last Modified',
		'Items',
	];
	const shortingColumnDate = ['End Date', 'Start Date', 'Last Modified'];
	const handleSort = (columnLabel: string) => {
		if (shortingColumn.includes(columnLabel)) {
			if (sortOrder.column === columnLabel) {
				setSortOrder((prevSortOrder) => ({
					column: columnLabel,
					order: prevSortOrder.order === 'asc' ? 'desc' : 'asc',
				}));
			} else {
				setSortOrder({
					column: columnLabel,
					order: 'asc',
				});
			}
		}
	};

	const formatDate = useCallback((date: any) => {
		const d = new Date(date);
		let month = '' + (d.getMonth() + 1),
			day = '' + d.getDate();
		const year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	}, []);

	const checkSearch = useCallback(
		(name: string | number | undefined) => {
			return name
				?.toString()
				?.toLowerCase()
				?.includes?.(searchedText.toLowerCase());
		},
		[searchedText]
	);

	const tableData = useMemo(
		() =>
			tableRowsData.filter((item) => {
				const strDate = new Date(
					item?.startdate || item?.enddate
				).toLocaleString('en-us', { month: 'long' });
				if (searchedText.length > 0) {
					if (
						checkSearch(item?.name) ||
						checkSearch(item?.email) ||
						checkSearch(item?.startdate) ||
						checkSearch(item?.enddate) ||
						checkSearch(strDate) ||
						checkSearch(item?.permissions) ||
						checkSearch(item?.title) ||
						checkSearch(item?.role) ||
						checkSearch(item?.lawFirmName) ||
						checkSearch(item?.lastmodified) ||
						checkSearch(item?.searchFileName)
					) {
						return true;
					}
					return false;
				}

				const date = new Date(formatDate(item.createdAt)).getTime();
				if (date >= selectedDate.fromDates && date <= selectedDate.endDates) {
					return item;
				}
				return <></>;
			}),
		[
			checkSearch,
			tableRowsData,
			searchedText,
			searchedText.length,
			selectedDate.endDates,
			selectedDate.fromDates,
		]
	);

	const { numberDecimal } = useFormatNumber();

	const renderColumn = useMemo(
		() =>
			column.map(({ label, key, width }) => {
				const keyName = `renderColumns__${label}_${key}`;
				return (
					<th
						className="table__head-data"
						key={keyName}
						style={{ width }}
						onClick={() => handleSort(label)}
					>
						<div className="table__head-data--label">
							<span>
{label}
{' '}
 </span>
							{sortOrder.column === label ? (
								<i
									className={`ri-arrow-${
										sortOrder.order === 'asc' ? 'up' : 'down'
									}-s-fill`}
								/>
							) : (
								shortingColumn.includes(label) && (
									<i className="ri-arrow-down-s-fill" />
								)
							)}
						</div>
					</th>
				);
			}),
		[column, sortOrder]
	);

	const renderRows = useMemo(() => {
		const renderingRows = [...tableData];
		if (sortOrder.column) {
			renderingRows.sort((a, b) => {
				let valueA =
					typeof a[sortOrder.column.toLowerCase()] === 'object'
						? a.searchFileName
						: a[sortOrder.column.toLowerCase()];
				let valueB =
					typeof b[sortOrder.column.toLowerCase()] === 'object'
						? b.searchFileName
						: b[sortOrder.column.toLowerCase()];

				const sortBy =
					sortOrder.column === 'Start Date' ||
					sortOrder.column === 'Last Modified'
						? sortOrder.column === 'Start Date'
							? 'startdate'
							: 'lastmodified'
						: 'enddate';

				if (shortingColumnDate.includes(sortOrder.column)) {
					valueA = new Date(a[sortBy]);
					valueB = new Date(b[sortBy]);
				}

				if (typeof valueA === 'string' && typeof valueB === 'string') {
					return sortOrder.order === 'asc'
						? valueA.localeCompare(valueB)
						: valueB.localeCompare(valueA);
				} else if (typeof valueA === 'number' && typeof valueB === 'number') {
					return sortOrder.order === 'asc' ? valueA - valueB : valueB - valueA;
				} else if (valueA instanceof Date && valueB instanceof Date) {
					return sortOrder.order === 'asc'
						? valueA.getTime() - valueB.getTime()
						: valueB.getTime() - valueA.getTime();
				}
				return 0;
			});
		}

		return renderingRows.map((item, index) => (
			<tr
				key={`renderRows_${item.createdAt}__${index}`} // eslint-disable-line
				className={`${columnHandle ? 'row__hover' : ''}`}
			>
				{column.map(({ key, format, width, className }, idx) => {
					let value = item[key]; // eslint-disable-line
					if (value) {
						switch (format) {
							case 'number':
								break;
							case 'date':
								value = `${getDate(value)}  ${getTime(value)}`;
								break;
							case 'dateTime':
								value = getDateWithTime(value);
								break;

							case 'price':
								value = `$${numberDecimal(Number(value))}`;
								break;
							case 'percentage':
								value = `${value}%`;
								break;
							default:
								break;
						}
					}
					return (
						<td
							key={`renderColumn_${index}__${idx}__${key}`} // eslint-disable-line
							className={`${className ? className : ''}`}
							style={{ width }}
						>
							<div
								className={`${
									format === 'string'
										? tableType === 'pipeLine'
											? 'text-elipsis-pipeline'
											: 'text-elipsis'
										: ''
								}`}
							>
								{isLoading
									? key !== 'action' && <Skeleton width={140} height={20} />
									: value == null || value === ''
									? '--'
									: value}
							</div>
						</td>
					);
				})}
			</tr>
		));
	}, [
		column,
		columnHandle,
		numberDecimal,
		tableData,
		tableType,
		sortOrder,
		isLoading,
	]);

	const isEmpty = useMemo(() => {
		if (!tableData.length && !renderRows.length) return true;
		return false;
	}, [renderRows.length, tableData.length]);

	return (
		<div className="react-table-responsive-container">
			{isButton && (
				<TableHeader
					handleAddNewClick={handleAddNewClick}
					showSearchBy={showSearch}
					showFilterBtn={true}
					ismultiheaderbtn={ismultiheaderbtn}
					headerbtnlable={headerbtnlable}
					headerSecondBtnLable={headerSecondBtnLable}
					handleSecondButtonClick={handleSecondButtonClick}
				/>
			)}
			<div className="react-table-responsive-wrapper">
				<table className={`react-table-responsive fl-table ${className}`}>
					<thead className="react-table-responsive__head">
						<tr
							className="react-table-responsive__head-row"
							style={{ backgroundColor: '#f1f2f2' }}
						>
							{renderColumn}
						</tr>
					</thead>
					{!!tableData.length && !!renderRows.length && (
						<tbody className="react-table-responsive__body ">
							{renderRows}
						</tbody>
					)}
				</table>

				{!tableData.length && isLoading && (
					<div className="loader-container">
						<Loader className="loader-blue" dimension={52} />
					</div>
				)}

				{isEmpty && !isLoading && (
					<TableEmptyMessage
						height={'250px'}
						image={emptyIllustration}
						description={emptyDescription}
					/>
				)}
			</div>
		</div>
	);
};
