export const DASHBOARD_SETUP_COMPANY = {
  ICON: "ri-building-line",
  HEADING: "Setup Company",
  INVITE_LIST: [
    {
      label: "Invite company adminstrators",
      desp: "Suspendisse vitae tortor sed ipsum. Nibh purus ipsum lacus urna donec.",
      btn_label: "Invite",
      complete: true,
      navigateKey: "ADMINISTRATION"
    },
    {
      label: "Invite board members",
      desp: "Suspendisse vitae tortor sed ipsum. Nibh purus ipsum lacus urna donec.",
      btn_label: "Invite",
      complete: true,
      navigateKey: "BOARD_MEMBERS"
    },
    {
      label: "Set roles and signatories",
      desp: "Suspendisse vitae tortor sed ipsum. Nibh purus ipsum lacus urna donec.",
      btn_label: "Assign Role",
      complete: true,
      navigateKey: "SIGNATORIES"
    },
    {
      label: "Invite legal contact",
      desp: "Suspendisse vitae tortor sed ipsum. Nibh purus ipsum lacus urna donec.",
      btn_label: "Invite",
      complete: true,
      navigateKey: "LAW_FIRM"
    },
  ],
};

export const DASHBOARD_EQUITY_MANAGEMENT = {
  ICON: "ri-equity-line",
  HEADING: "Equity Management",
  INVITE_LIST: [
    {
      label: "Create equity plan",
      desp: "Suspendisse vitae tortor sed ipsum. Nibh purus ipsum lacus urna donec.",
      btn_label: "Add",
      complete: true,
    },
    {
      label: "Add stakeholder",
      desp: "Legal contact must be a TrueCap user, and will granted full access to your account.",
      btn_label: "Add",
      complete: false,
    },
    {
      label: "Draft options",
      desp: "Suspendisse vitae tortor sed ipsum. Nibh purus ipsum lacus urna donec.",
      btn_label: "Add",
      complete: true,
    },
    {
      label: "Invite legal contact",
      desp: "Suspendisse vitae tortor sed ipsum. Nibh purus ipsum lacus urna donec.",
      btn_label: "Draft",
      complete: true,
    },
  ],
};
