import { Input } from '@storybook';
import './share-modal.scss';
import { useCallback, useState } from 'react';
// import Modal from '@storybook/modal';
// import { CaptableGrid } from '@storybook/captable-grid';
// import { ShareSheet } from '../share-sheet';

export const ShareModal = () => {
	// const [open, setOpen] = useState(false);
	// const [close, setClose] = useState(false);

	// const handleClick = () => {
	// 	setOpen(true);
	// 	// setClose(!close);
	// };
	// const handleClose = () => {
	// 	setOpen(false);
	// };
	const [addMemberData, setAddMemberData] = useState<any>({
		startDate: '',
		endDate: '',
	});
	const handleChangeData = useCallback(
		(type: string, e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setAddMemberData((prevState: any) => {
				const newState: any = { ...prevState };
				newState[type] = value;
				return newState;
			});
		},
		[addMemberData]
	);

	return (
		<>
			<div className="share-modal">
				<div className="share">
					<div className="container">
						<div className="share- details">
							<div className="share-text">
								Upload Article of Incorporation(AOI) that authorizes this
								current share class.
							</div>
							<div className="share-sub">
								This will create a new version of your Rights & Preferences
								table, preserving your current
							</div>
							<div className="share-sub">values in historical version.</div>
						</div>

						<div className="board-box-share">
							<div className="input-border-share">
								<label className="input__label-share">
									Article of Incorporation(AOI)
									<span className="required">(Required)</span>
								</label>
								<div className="input__value-container-share">
									<div className="input__group-share">
										<div className="input__text-field-share">
											<input
												placeholder=""
												type="text"
												className="input__field"
												value=""
											/>
											<div className="button-box">
												<button className="btn">Browse</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="calender-body-row">
							<div className="calender-date">
								<Input
									inputType={'date'}
									placeholder={'Start Date'}
									label="Filing date"
									handleChange={(event) => handleChangeData('startDate', event)}
									required
									value={addMemberData.startDate}
								/>
							</div>
							<div className="input-right-share">
								<Input
									inputType={'text'}
									placeholder="Series A Fundraiser"
									label="AOI Name"
									handleChange={(event) => handleChangeData('aoiName', event)}
									required
									value={addMemberData.startDate}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
