import { Button, ReactDropdown, ReactMonthDropdown } from '@storybook';
import './equity.scss';

export const Equityday = () => {
	return (
		<div className="equity-day-container">
			<div className="equity-details-monthly">
				<div className="equity-day-body">
					<div className="equityouter">
						<div className="term-of-expiration">
							<span className="term">Terms of Expiration</span>
							<div className="line-1"></div>
						</div>
						<div className="company-details-input-wrapper">
							<div className="company-details-outer">
								<div className="input-term">
									<label className="input__label">Term of plan</label>
									<div className="input__group__dropdown">
										<div className="input__select-field">
											<ReactDropdown options={[]} />
										</div>
									</div>
								</div>
								<div className="input-term">
									<label className="input__label">
										Option grant expiration{' '}
									</label>
									<div className="input__group__dropdown">
										<div className="input__select-field">
											<ReactDropdown options={[]} />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="exercise">
							<div className="term-of-expiration">
								<span className="term">Exercise Period</span>
								<div className="line-2"></div>
							</div>

							<div className="volume-wrapper">
								<div className="volumne-outer">
									<input type="checkbox" />
									<span className="voluntary">Voluntary termination</span>
									<div className="month-container">
										<input type="text" className="checkbox" />
										<ReactMonthDropdown options={[]} />
									</div>
								</div>
								<div className="volumne-outer">
									<input type="checkbox" />
									<span className="voluntary">Involuntary termination</span>
									<div className="month-container">
										<input type="text" className="checkbox" />
										<ReactMonthDropdown options={[]} />
									</div>
								</div>
								<div className="volumne-outer">
									<input type="checkbox" />
									<span className="voluntary">Termination with cause</span>
									<div className="month-container">
										<input type="text" className="checkbox" />
										<ReactMonthDropdown options={[]} />
									</div>
								</div>
								<div className="volumne-outer">
									<input type="checkbox" />
									<span className="voluntary">Death</span>
									<div className="month-container">
										<input type="text" className="checkbox" />
										<ReactMonthDropdown options={[]} />
									</div>
								</div>
								<div className="volumne-outer">
									<input type="checkbox" />
									<span className="voluntary">Disability</span>
									<div className="month-container">
										<input type="text" className="checkbox" />
										<ReactMonthDropdown options={[]} />
									</div>
								</div>
								<div className="volumne-outer">
									<input type="checkbox" />
									<span className="voluntary">Retirement</span>
									<div className="month-container">
										<input type="text" className="checkbox" />
										<ReactMonthDropdown options={[]} />
									</div>
								</div>
							</div>
							<div className="repurchase">
								<span className="term">Repurchase Period</span>
								<div className="line-1"></div>
							</div>
							<div className="volume-wrapper">
								<div className="volumne-outer">
									<input type="checkbox" />
									<span className="voluntary">Repurchase period</span>
									<div className="month-container">
										<input type="text" className="checkbox" />
										<ReactMonthDropdown options={[]} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="add-footer">
						<div className="add-member-footer">
							<div className="add-member-footer__right">
								<Button
									label={'Cancel'}
									type={'sumbit'}
									fontColor="black"
									backgroundcolor="#f0f4ff"
									width="80px"
									borderRadius="4px"
								/>
								<Button
									label={'Create'}
									width="80px"
									backgroundcolor="black"
									borderRadius="4px"
									type={'sumbit'}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
