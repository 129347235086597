import { useCallback, useMemo } from "react";
import classNames from "classnames";

import "./toggle-route.scss";
import { routeState } from "states";
import { useRecoilValue } from "recoil";

interface SUB_ROUTE {
  ROUTE: string;
  TITLE: string;
  ICON: string;
}
interface ROUTE {
  ROUTE: string;
  TITLE: string;
  ICON: string;
  TAB_INDEX: number;
  SUB_ROUTE: Array<SUB_ROUTE>;
}
interface AppProps {
  index: number;
  route: ROUTE;
  handleChangeTab: any;
  pathname: string;
  openToggle: string;
  setOpenToggle: any;
  windowSize?: number[];
}

export const ToggleRoute = (props: AppProps) => {
  const { index, pathname, handleChangeTab, route, openToggle, setOpenToggle } =
    props;

  const isrouteState = useRecoilValue(routeState);

  const toggleIconclasss = classNames(
    "ri-arrow-down-s-line toggle-description-icon ",
    {
      "ri-arrow-up-s-line": openToggle === route.TITLE,
    }
  );

  const handleOpen = useCallback(() => {
    if (route.TITLE === openToggle) {
      setOpenToggle("");
    }
  }, [openToggle, route.TITLE, setOpenToggle]);

  const conditionalActiveTab = useCallback((tabName: string) => {
    if (
      isrouteState && tabName === "Setup" ||
      tabName === "Dashboard" ||
      tabName === "Data Rooms" ||
      tabName === "Financials" ||
      tabName === "Roles & Permission" ||
      tabName === "Shares" ||
      tabName === "Options" ||
      tabName === "Warrant" ||
      tabName === "Convertibles" ||
      tabName === "Templates" ||
      tabName === "Stakeholder" ||
      tabName === "Share Class" ||
      tabName === "Equity Plan" ||
      tabName === "Settings"
    ) {
      return true;
    } else {
      return false;
    }
  }, []);

  const subRouteMemo = useMemo(() => {
    return route.SUB_ROUTE.map((subRoute, index) => {
      if (conditionalActiveTab(subRoute.TITLE)) {
        return (
          <li
            key={index} // eslint-disable-line
            className={
              pathname === `/${subRoute.ROUTE}` ||
              pathname.includes(`/${subRoute.ROUTE}`)
                ? "toggle--pad--active"
                : ""
            }
            onClick={() => {
              handleChangeTab(subRoute.ROUTE);
            }}
          >
            <div>
              <i className={`sidebar__icon ${subRoute.ICON}`} />
            </div>
            <div> 
{' '}
{subRoute.TITLE}
</div>
          </li>
        );
      }
      return <></>;
    });
  }, [route.SUB_ROUTE, conditionalActiveTab, pathname, handleChangeTab]);

  return (
    <div
      className="toggle-description"
      key={index}
      onClick={() => {
        if (route.TITLE !== openToggle) {
          setOpenToggle(route.TITLE);
        }
      }}
    >
      <div className="toggle--main" onClick={handleOpen}>
        <span className="toggle-title">
{route.TITLE}
</span>
        <i className={toggleIconclasss} />
      </div>

      {openToggle === route.TITLE && (
        <div className="toggle--sub">
          <ul className="toggle--pad">
{subRouteMemo}
</ul>
        </div>
       )}  
    </div>
  );
};
