import { Header } from 'views/header';
import { Equityplan } from './components/equityempty/equityplan';

import './equity.scss';

export const Equity = () => {
	return (
		<div className="outer">
			<Header title="Equity Plan" />
			<Equityplan />
		</div>
	);
};
