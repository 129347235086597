import { FC, useCallback, useEffect, useState } from 'react';
import { Button, Input, Loader, ReactDropdown } from '@storybook';
import './add-member.scss';
import { useNetwork } from 'hooks';
import { useSetRecoilState } from 'recoil';
import { MemberListData } from 'states/memberstate';
import { SUB_PERMISSION_TYPE, SUB_ROLE_TYPE, USER_ROLE_TYPE, USER_TYPE } from '../constant';
import TagInput from '../tag-input/TagInput';
import { getPermissionLable, validateEmailString } from 'hooks/web-authentication/utils';
import { API_URL } from 'constant';
interface IModal {
	visible: boolean;
	handleClose: () => void;
	headingLabel: string;
	getModalData: any;
}

interface IRolePermissions {
	titles:  string[];
	permissions: string;
}
interface IAddMemberData {
	firstName: string;
	lastName: string;
	email: string;
	status:string;
	role: string[];
	rolePermissions:IRolePermissions;
}

export const AddAdministrationMember: FC<IModal> = ({
	visible,
	handleClose,
	headingLabel,
	getModalData
}) => {
	const SetMemberList = useSetRecoilState(MemberListData);
	// const modalButtonLable = useRec
	// api state
	const { post: addMember, loading: addMemberLoading } = useNetwork();
	const { get: getMemberList } = useNetwork();
	const { patch: updateMemberData, loading: updateMemberLoading } = useNetwork();
	// local states
	
	const [addMemberData, setAddMemberData] = useState<IAddMemberData>({
		firstName: getModalData?.firstName ?? '',
		lastName: getModalData?.lastName ?? '',
		email: getModalData?.email ?? '',
		status: 'Not yet registered',
		role: [headingLabel],
		rolePermissions: {
			titles: getModalData?.rolePermissions?.titles ?? '',
			permissions: getModalData?.rolePermissions?.permissions ?? '',
		},
	});
	const [tags, setTags] = useState<string[]>([]);
	const [inputValue, setInputValue] = useState('');
	const [error, setError] = useState(false);
	const [isloading, setIsLoading] = useState(false);

	const controlStyle = {
		minHeight: 42,
		minWidth: 180,
		height: 42,
		borderRadius: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.06)',
		'&:hover': { borderColor: 'black', cursor: 'pointer' },
		fontWeight: 500,
		fontSize: 14,
	};

	const optionStyle = {
		height: 42,
		minHeight: 42,
		'&:hover': { background: 'var(--color-black)', color: 'white' },
	};

	const getmembersdata = () => {
		getMemberList(`${API_URL.ROLES_PERMISSION}${headingLabel}`).then((data) =>
			SetMemberList(data?.data)
		);
	};

	useEffect(() => { 
		if (getModalData) setTags(getModalData?.rolePermissions?.titles)
	}, []);

	

	useEffect(() => {
		setAddMemberData({
			...addMemberData,
			rolePermissions: { ...addMemberData.rolePermissions, titles: tags },
		});
		setIsLoading(false)
	}, [tags]);

	if (!visible) {
		return null;
	}
	/* eslint-disable */
	const handleChangeData = useCallback(
		(type: string, e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setAddMemberData((prevState: any) => {
				const newState: any = { ...prevState };
				newState[type] = value;
				return newState;
			});
			const isValidEmail = validateEmailString(addMemberData.email);
			if (type === 'email') {
				if (isValidEmail) {
					setError(false);
				} else {
					setError(true);
				}
			}
		},
		[addMemberData,error]
	);

	const handleDropChange = useCallback(
		(item: { label: string; value: string }) => {
		  setAddMemberData((prevState) => ({
			...prevState,
			rolePermissions: {
			  ...prevState.rolePermissions,
			  permissions: item.value
			}
		  }));
		},
		[setAddMemberData]
	);

	const handleAddClick = async () => {
		setIsLoading(true);
		const payload = {
			...addMemberData,
		};

		{!getModalData &&
				(await addMember(`${API_URL.USER_INVITE}`, payload).finally(() => {
					getmembersdata();
				}));
		}

		{getModalData &&
				(await updateMemberData(`${API_URL.USERS}${getModalData._id}`, {
					firstName: addMemberData.firstName,
					lastName: addMemberData.lastName,
					role: [USER_TYPE.ADMINISTRATION],
					titles: addMemberData.rolePermissions.titles,
				}).finally(() => {
					getmembersdata();
				}));
		}
	};

	useEffect(() => {
		if(isloading && addMemberLoading===false && updateMemberLoading===false) {
			setIsLoading(false);
			handleClose();
		}
	}, [updateMemberLoading,addMemberLoading,isloading])

	const renderButtonLable = useCallback(() => {
		return (
			<>
				{isloading ? (
					<Loader className="loader-blue" type="loader" dimension={16} />
				) : getModalData ? (
					'Update'
				) : (
					'Invite'
				)}
			</>
		);
		
	},[isloading])
	
const isDisabled = () => {
	const isValidEmail = validateEmailString(addMemberData.email);

	const isDataMatch =
		addMemberData.firstName !== getModalData?.firstName ||
		addMemberData.lastName !== getModalData?.lastName ||
		addMemberData.rolePermissions.titles.length !==
			getModalData.rolePermissions.titles.length ||
		addMemberData.rolePermissions.titles.some(
			(title, index) => title !== getModalData.rolePermissions.titles[index]
		) ||
		addMemberData.rolePermissions.permissions !==
			getModalData?.rolePermissions.permissions ||
		addMemberData.email !== getModalData?.email;

	const isValidData =
		addMemberData.firstName &&
		addMemberData.lastName &&
		isValidEmail &&
		addMemberData.rolePermissions.titles.length &&
		addMemberData.rolePermissions.permissions;
	if (getModalData ? isDataMatch && isValidData : isValidData) {
		return false;
	}
	return true;
};
	return (
		<div className="modal-main">
			<div className="modal-main__content">
				<div className="modal-main__heading-row">
					<div className="modal-main__label">{getPermissionLable(headingLabel,USER_ROLE_TYPE)}</div>
					<div className="modal-main__delete-icon">
						<i
							className="ri-close-circle-line"
							onClick={() => handleClose()}
						></i>
					</div>
				</div>
				<div className="form-body">
					<div className="form-body__row">
						<div className="form-body__half-space">
							<Input
								handleChange={(event) => handleChangeData('firstName', event)}
								inputType={'text'}
								placeholder={'First Name'}
								label="First Name"
								value={addMemberData?.firstName}
								required
							/>
						</div>
						<div className="form-body__half-space">
							<Input
								inputType={'text'}
								placeholder={'Last Name'}
								label="Last Name"
								handleChange={(event) => handleChangeData('lastName', event)}
								value={addMemberData?.lastName}
								required
							/>
						</div>
					</div>
					<Input
						inputType={'text'}
						placeholder={'Email'}
						label="Email"
						handleChange={(event) => handleChangeData('email', event)}
						value={addMemberData?.email}
						isError={error}
						required
					/>
					<div className="form-body__margin-top">
						<div
							style={{
								fontSize: '13px',
								fontWeight: 500,
								paddingBottom: '10px',
								paddingTop: '12px',
							}}
						>
							Titles
							<span style={{ marginLeft: '5px', color: '#DE7935' }}>
								(Required)
							</span>
							<TagInput
								tags={tags}
								setTags={setTags}
								inputValue={inputValue}
								setInputValue={setInputValue}
							/>
						</div>
					</div>

					<div
						style={{
							fontSize: '13px',
							fontWeight: 500,
							paddingBottom: '10px',
							paddingTop: '12px',
						}}
					>
						Permissions
						<span style={{ marginLeft: '5px', color: '#DE7935' }}>
							(Required)
						</span>
						<ReactDropdown
							controlStyle={controlStyle}
							optionsStyle={optionStyle}
							handleChangeSelect={handleDropChange}
							options={SUB_PERMISSION_TYPE}
							getOptionLabel={(option: any) => (
								<div>
									<div>{option.label}</div>
									<div style={{ fontSize: '12px', color: '#7A7A7A' }}>
										{option.description}
									</div>
								</div>
							)}
							value={{
								label: addMemberData.rolePermissions.permissions.length
									? getPermissionLable(addMemberData.rolePermissions.permissions,SUB_PERMISSION_TYPE)
									: 'Select',
								value: addMemberData.rolePermissions.permissions,
							}}
							defaultValue={SUB_ROLE_TYPE[0]}
						/>
					</div>
				</div>
				<div className="add-member-footer">
					<div className="add-member-footer__right">
						<Button
							label={'Cancel'}
							width="100px"
							handleClick={() => handleClose()}
							type={'sumbit'}
							fontColor="black"
							backgroundcolor="#f0f4ff"
						/>
						<Button
							label={renderButtonLable()}
							width="100px"
							backgroundcolor="black"
							handleClick={() => handleAddClick()}
							type={'sumbit'}
							disabled={isDisabled()}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
